.copywriting{
  .cta-1{
    box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.5);
    border-radius: 14px;
    background: #9e0722;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.38px;
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 20px;
    padding-right: 20px;
  }
  .cta-2{
    box-shadow: -7px 7px 18px 3px rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    background: #ffffff;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    letter-spacing: -0.38px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sign-btn, .log-btn{
    font-weight: 400;
    font-size: 20px;
    min-width: 110px;
    transition: 400ms;
  }
}