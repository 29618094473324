.faq{
  background-image: url(images/oval-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
  &-title{
    color: #9e0722;
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid #979797;
    background: rgba(216, 216, 216, 0.65);
    padding: 15px 35px;
    font-family: Helvetica;
  }
  &-questions{
    color: #fff;
    font-size: 24px;
    margin-bottom: 0;
    &-question{
      font-size: 21px;
    }
    &-answer{
      padding: 20px 0;
      font-size: 21px;
    }
  }
  .cta-1{
    min-width: 150px;
  }
  @media (max-width: 500px){
    &-title{
      font-size: 28px;
      padding: 15px 0;
    }
  }
}