.price{
  &-wrap{
    background: url(images/oval-bg.png) repeat 50% 50%, linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
    background-size: cover !important;
    .component-title-white{
      text-align: center;
      margin-bottom: 20px;
    }
    .component-subtitle-white{
      text-align: center;
      max-width: 1000px;
      margin: auto;
    }


    .note-line{
      .border-radius(4px);
      border: 2px solid #636976;
      max-width: 900px;
      margin: 50px auto 0;
      padding: 10px 20px;
      color: @wc;
      &-icon{
        background-color: @wc;
        .border-radius(100%);
        padding: 10px 8px;
        color: @gc2;
        display: inline-grid;
        margin-right: 20px;
        font-size: 20px;
        @media all and  (max-width: (@max-sm + 1px)){
          margin: 0 20px 10px 0;
          float: left;
        }
      }
    }
  }
  &-grid{
    text-align: center;
    margin-top: 60px;
    line-height: 24px;
    &-title{
      color: @wc;
      font-size: 24px;
      font-weight: 400;
    }
    &-desc{
      @media all and  (max-width: @max-sm){
        font-size: 11px;
      }
    }
    &-table{
      margin-top: 10px;
    }
    .component-item{
      flex: 0 0 49%;
      @media all and  (max-width: (@max-sm + 1px)){
        min-width: 100%;
      }
    }
    .component-item:last-child {
      @media all and  (max-width: (@max-sm + 1px)){
        margin-top: 30px;
      }
    }
  }
  &-grid-header,
  &-grid-content{
    flex-direction: row;
    width: 100%;
  }
  &-grid-header,
  &-grid-item{
    &-td{
      flex: 1 0 33%;
    }
  }
  &-grid-header{
    padding: 10px 0;
    font-size: 14px;
    @media all and  (max-width: (@max-sm + 1px)){
      display: none;
    }
    &-td{
      &:first-child{
        text-align: left;
        padding-left: 15px;
      }
    }
  }
  &-grid-item{
    margin: 10px 0;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.24));
    padding: 18px;
    background-color: @wc;
    .border-radius(4px);
    &-td{
      &:first-child{
        text-align: left;
      }
    }

    @media all and  (max-width: (@max-sm + 1px)){
      align-items: flex-end;
      text-align: right;
      &-td{
        width: 100%;
        &:before{
          float: left;
          color: @gc2;
        }
        &:first-child{
          text-align: right;
          &:before{
            content: 'Order Level';
          }
        }
        &:nth-child(2){
          &:before{
            content: 'Urgent ( up to 24h)';
          }
        }
        &:last-child{
          &:before{
            content: 'Non-urgent (from 24h)';
          }
        }
      }
    }
  }
}
