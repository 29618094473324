.copywriting {
  &-testimonials {
    .testimonials {
      &-item {
        margin-bottom: 80px;
        box-shadow: 2px 3px 4px 2px rgba(0, 0, 0, 0.5);
        border-radius: 6px;
        padding: 0;
      }
      &-inner {
        padding: 20px 10px 20px 25px;
        display: flex;
        align-items: center;
      }
      &-icon {
        width: 20%;
      }
      &-info {
        width: 80%;
      }
      &-text {
        &:before {
          display: none;
        }
      }
    }
    .component {
      &-title-white {
        text-align: center;
        font-size: 48px;
        margin-bottom: 100px;
      }
      &-subtitle-white {
        text-align: center;
        font-size: 23px;
        line-height: 35px;
      }
      &-item {

        &:first-child {
          flex: 0 0 39%;
        }
        &:last-child {
          flex: 0 0 50%;
          height: auto;
        }
      }
      &-question {
        color: #eaeaea;
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 150px;
        text-align: center;
        margin-bottom: 45px;
      }
    }
    .cta-1{
      font-size: 20px;
    }
    @media (max-width: 992px){
      .component{
        &-title-white{
          margin-bottom: 40px;
          font-size: 48px;
        }
        &-subtitle-white{
          font-size: 20px;
          line-height: 30px;
        }
        &-question{
          margin-top: 40px;
          margin-bottom: 0;
        }
        &-item{
          &:last-child{
            padding-left: 0;
          }
        }
      }
      .testimonials-item{
        margin-bottom: 20px;
      }
    }
    @media (max-width: 340px){
      .component{
        &-question{
          font-size: 30px;
        }
      }
      .cta-1{
        font-size: 18px;
      }
      .testimonials {
        &-inner{
          display: block;
        }
      }
    }
  }
}