.copywriting {
  &-growth{
    .component{
      &-title{
        font-family: @roboto;
      }
      &-subtitle{
        color: #000000;
        font-size: 23px;
        line-height: 35px;
        max-width: 88%;
        margin-top: 60px;
        margin-bottom: 90px;
      }
    }
    .growth{
      &-item{
        flex: 0 0 20%;
        padding: 25px 20px 220px 20px;
        box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.5);
        &-title{
          color: #9e0722 !important;
          font-size: 24px;
          font-weight: 700;
          text-align: center;
          line-height: 60px;
          &:after{
            content: '';
            width: 100%;
            height: 1px;
            background: #9e0722;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
            display: block;
          }
        }
        &-list{
          &-info{
            color: #717070;
            font-size: 16px;
            text-align: center;
            &:before, &:after{
              display: none;
            }
          }
          &:before, &:after{
            display: none;
          }
        }
      }
    }

    @media (max-width: 992px){
      .growth-gradation-list {
        display: block;
      }
      .growth-item {
        max-width: 200px;
        margin: 0 auto 25px;
      }
      .component-subtitle {
        font-size: 20px;
        line-height: 30px;
        max-width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }
  }
}