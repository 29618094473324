.copywriting{
  &-about{
    padding: 70px 0 60px;
    .component-subtitle-white {
      font-size: 19px;
      max-width: 100%;
      line-height: 24px;
    }
    .component-item{
      &:first-child{
        flex: 1 0 66%;
      }
      &:last-child{
        flex: 1 0 34%;
      }
    }
    .about-us-list{
      justify-content: center;
      .about-us{
        &-item{
          flex: 0 0 70%;
          box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.5);
          border-radius: 21px;
          &:nth-child(2n){
            top: 0;
          }
        }
        &-title{
          font-size: 18px
        }
      }
    }
    .cta-2{
      background-image: linear-gradient(180deg, #e7e7e7 0%, #ffffff 100%);
      margin-top: 40px;
      margin-bottom: 15px;
      font-size: 20px;
    }
    .text-center{
      text-align: center;
      margin: 15px 0;
    }
    .content-accordion{
      &__header{
        box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.5);
        border: 1px solid #000000;
        background-color: #630516;
        color: #fff;
        font-size: 22px;
        &:before{
          background-color: #b3a2a2;
        }
      }
      &__body{
        background-color: rgba(255, 255, 255, 0.21);
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.38px;
        padding: 20px 10px;
        position: relative;
        top: -13px;
      }
    }
    @media (max-width: 767px){
      .about-us-list{
        .about-us-item {
            margin-bottom: 20px;
        }
      }
    }
    @media (max-width: 579px){
      .about-us-list {
        max-width: 100%;
      }
    }

  }
}