.copywriting {
  &-main-screen {
    .main-screen-content {
      padding-top: 100px;
      padding-bottom: 300px;
      .component {
        &-title {
          text-align: center;
          font-size: 48px;
          margin-bottom: 10px;
        }
        &-subtitle {
          text-align: center;
          color: #abacad;
          font-size: 24px;
        }
        &-text {
          color: #000000;
          font-size: 24px;
          line-height: 35px;
          max-width: 460px;
          margin-top: 100px;
        }
      }
    }
    .animation{
      .picture{
        .picture__boy{
          top: 35.5%;
        }
        .picture__stairs{
          top: 43.2%;
        }
      }
    }
    @media (max-width: 1080px){
      .main-screen-content{
        .component{
          &-subtitle{
            width: 100%;
          }
          &-text{
            margin-top: 150px;
            max-width: 370px;
          }
        }
      }

      .animation {
        .academia{
          top: 70px;
        }
        .picture{
          .picture__boy{
            top: 37.5%;
          }
          .picture__stairs{
            top: 44.5%;
          }
        }
      }
    }
    @media (max-width: 992px){
      .main-screen-content{
        .component{
          &-text{
            max-width: 370px;
            margin: 40px auto 0;
          }
        }
      }
    }
    @media (max-width: 500px){
      .main-screen-content{
        .component-title {
          font-size: 30px;
          line-height: 35px;
        }
      }

    }
  }
}