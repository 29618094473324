.orders{
  &-wrap{
    .linear-gradient(140deg, #ffffff, #e3e7e8);
    padding-top: 130px;
    padding-bottom: 60px;

    .component-item:first-child{
      flex: 0 0 40%;
      .component-title{
        @media all and  (min-width: @min-md){
          font-size: 48px;
          margin-top: 60px;
        }
      }
      .component-subtitle{
        @media all and  (min-width: @min-lg){
          max-width: 400px;
        }
      }
    }
    .component-item:last-child{
      flex: 0 0 60%;
    }

    @media screen and  (max-width: @min-sm ){
      padding-top: 30px;
    }
  }
}

.recent-orders{
  &-table{
    flex-direction: column;
    align-items: flex-end;
    color: @gc2;
    line-height: 24px;
    font-size: 14px;
  }
  &-header,
  &-item{
    justify-content: space-between;
    width: 100%;
    &-td{
      &:first-child{
        flex: 0 0 34%;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:last-child{
        flex: 1 0 7%;
        text-align: center;
      }
    }
    @media all and  (min-width: (@max-sm + 1px)) {
      flex-direction: row;
    }
  }
  &-header{
    @media all and  (max-width: (@max-sm + 1px)) {
      flex-direction: row;
      margin-top: 20px;
    }
    @media all and  (max-width: @max-sm){
      display: none;
    }
    &-td {
      margin-bottom: 10px;
      &:first-child {
        padding-left: 35px;
      }
      &:last-child {
        padding-right: 25px;
        text-align: right;
      }
    }
  }
  &-item{
    padding: 17px 0;
    .border-radius(6px);
    background-color: @wc;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.24));
    margin: 7px 0;
    &-td{
      &:first-child{
        color: @gc3;
        padding-left: 35px;
      }
      &:last-child {
        color: #288a1a;
        text-align: right;
        padding-right: 25px;
      }
    }
    @media all and  (max-width: @max-sm){
      //padding: 17px 15px;
      margin: 5px;
      text-align: right;
      &-td{
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:last-child{
          text-align: right;
          padding: 5px 0;
          &:before{
            display: inline-block;
            float:left;
            color: @gc2;
            font-weight: 700;
          }
        }
        &:first-child{
          &:before{
            content: 'Topic';
          }
        }
        &:nth-child(2){
          &:before{
            content: 'Level';
          }
        }
        &:nth-child(3){
          &:before{
            content: 'Deadline';
          }
        }
        &:nth-child(4){
          &:before{
            content: 'Number of pages';
          }
        }
        &:last-child{
          &:before{
            content: 'Salary';
          }
        }
      }
    }
    @media all and  (max-width: @max-xs) {
      //padding: 17px 0;
      //margin: 0;
      display: block;
      &-td{
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:last-child{
          //width: 100%;
          padding: 5px 15px;
        }
      }
    }
  }
  &-btn{
    width: 100%;
    text-align: center;
    @media all and  (min-width: @min-md){
      text-align: right;
    }
  }
  &-slider{
    width: 100%;
    @media all and  (max-width: @max-sm){
      margin-top: 20px;
    }
  }
}

.more-btn{
  display: inline-block;
  text-align: center;
  margin-top: 25px;
  padding: 5px 30px;
  color: @rc2;
  font-weight: 700;
  border: 1px solid @rc2;
  .border-radius(6px);
}