.main-screen{
  background: url(images/main-bg.png) no-repeat 82% 64%, linear-gradient(137deg, #e3e7e8 0%, @wc 50%);
  position: relative;
  &.pay-rates{
    background: linear-gradient(137deg, #e3e7e8, @wc);
    & .main-screen-content{
      padding-top: 175px;
      padding-bottom: 110px;
      &:after{
        content: '';
        background: url(images/pr-img.png) no-repeat 100% 90%;
        position: absolute;
        bottom: 5%;
        right: -20px;
        width: 590px;
        height: 410px;
        display: block;
        z-index:-1;
      }
      text-align: left;
      .component-title, .component-subtitle{
        text-align: left;
      }
      @media all and  (max-width: (@max-lg - 1px) ) {
        &:after {
          right: 0;
        }
      }
      @media all and  (max-width: (@max-sm + 1px) ) {
        padding-top: 100px;
        padding-bottom: 0px;
        &:after{
          position: relative;
          right: 0;
          margin: auto;
          width: 300px;
          height: 210px;
          background-size: 100% 100%;
          background-position: 50% 100%;
          margin-top: 60px;
        }
        text-align: center;
        .component-title, .component-subtitle{
          text-align: center;
        }
      }
    }
  }
  &.hts{
    background: linear-gradient(137deg, #e3e7e8, @wc);
    & .main-screen-content{
      padding-top: 175px;
      padding-bottom: 110px;
      &:after{
        content: '';
        background: url(images/hts-img.png) no-repeat 100% 90%;
        position: absolute;
        bottom: 5%;
        right: -75px;
        width: 650px;
        height: 410px;
        display: block;
      }
      @media all and  (max-width: (@max-lg - 1px) ) {
        &:after {
          right: 0;
        }
      }
      @media all and  (max-width: (@max-sm + 1px) ) {
        padding-top: 100px;
        padding-bottom: 0px;
        &:after{
          position: relative;
          right: 0;
          margin: auto;
          width: 300px;
          height: 210px;
          background-size: 100% 100%;
          background-position: 50% 100%;
          margin-top: 60px;
        }
      }
    }
  }
  @media screen and  (max-width: 1440px) {
    background: url(images/main-bg.png) no-repeat 100% 64%, linear-gradient(137deg, #e3e7e8 0%, #ffffff 50%);
  }
  @media screen and  (max-width: @max-md){
    background: url(images/banner-mob.png) no-repeat 50% 100%, linear-gradient(137deg, #e3e7e8, @wc);
  }
    &-content {

      @media all and  (min-width: 1000px) {
        padding-top: 130px;
        padding-bottom: 175px;
      }

      @media all and  (min-width: 1040px) {
        padding-top: 230px;
        padding-bottom: 185px;
      }
      @media all and  (max-width: (@max-md + 1px) ) {
        padding-top: 125px;
        padding-bottom: 280px;
        .component-title,
        .component-subtitle{
          text-align: center;
        }
      }
    }
  .cta-1{
    @media all and  (min-width: @min-md){
      margin-top: 55px;
    }
  }

}