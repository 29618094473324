.article,
.page__content {
  @media all and  (max-width: 991px) {
    padding: 40px 0;
  }
  @media all and  (min-width: 992px) and (max-width: 1199px) {
    padding: 50px 0;
  }
  @media all and  (min-width: 1200px) {
    padding: 80px 0;
  }
}