.flex {
  display: flex;
  &-ai-c {
    align-items: center;
  }
  &-ai-s {
    align-items: stretch;
  }
  &-ai-flEnd {
    align-items: flex-end;
  }
  &-ai-flStar {
    align-items: flex-start;
  }
  &-jc-c {
    justify-content: center;
  }
  &-jc-spBet {
    justify-content: space-between;
  }
  &-jc-spAr {
    justify-content: space-around;
  }
  &-jc-flStar {
    justify-content: flex-start;
  }
  &-jc-flEnd {
    justify-content: flex-end;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-ac-flStar {
    align-content: flex-start;
  }
  @media all and  (max-width: @max-md) {
    flex-direction: column;
  }
}