.header-copywriting{
  .header-item.header-item_logo{
    flex: 0 0 150px;
    margin-right: 0;
    transition: 400ms;
    img{
      width: 150px;
      transition: 400ms;
    }
  }
  #menu-nav-menu{
    text-align: center;
    li{
      a{
        font-size: 21px;
        font-family: Arial;
        transition: 400ms;
      }
    }
  }
  &.scrollr{
    .header-item.header-item_logo{
      flex: 0 0 110px;
      img{
        width: 110px;
      }
    }
    #menu-nav-menu{
      li{
        a{
          font-size: 18px;
        }
      }
    }
    .sign-btn, .log-btn{
      font-size: 16px;
      min-width: 80px;
    }
  }
  @media (max-width: 992px){
    .header-item.header-item_logo{
      flex: 0 0 110px;
      img{
        width: 110px;
      }
    }
    #menu-nav-menu{
      li{
        a{
          font-size: 18px;
        }
      }
    }
    .sign-btn, .log-btn{
      font-size: 16px;
      min-width: 80px;
    }
  }
  @media (max-width: 767px){
    .main-nav{
      padding-top: 80px;
      .menu-item {
        margin-bottom: 5px;
      }
    }
  }
}