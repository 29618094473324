.about-job2{
  &-wrap{
    padding: 50px 0;
    .component-title{
      text-align: center;
      @media all and  (min-width: @min-md){
        font-size: 32px;
      }
      margin-bottom: 35px;
    }
  }
  &-accordion{
    @media all and  (min-width: @min-md) {
      background-image: url(images/seo-bg.png);
      background-repeat: no-repeat;
      background-position: 100% 0;
      min-height: 650px;
    }
    .flex-first{
      @media all and  (min-width: @min-md){
        flex: 0 0 57%;
      }
    }
  }
}

.content-accordion{
  &__header{
    background-color: @wc;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.24));
    padding: 17px 20px;
    font-size: 23px;
    line-height: 20px;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    z-index: 10;
    &:before{
      content: '\f107';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      display: inline-block;
      font-size: 24px;
      color: @rc2;
      text-align: center;
      background-color: #ececec;
      .border-radius(100%);
      width: 29px;
      height: 29px;
      line-height: 30px;
      float: right;
      position: relative;
      top: -5px;
      @media all and  (max-width: @max-lg){
        font-size: 14px;
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
    &:after{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }

    &.active{
      &:before {
        content: '\f106';
      }
    }
    @media all and  (max-width: @max-lg){
      font-size: 14px;
    }

    h1, h2, h3{
      line-height: 20px;
      margin: 0;
      font-size: 23px;
      font-family: @roboto;
      font-weight: 400;
    }
  }
  &__body{
    //display: none;
    padding-top: 5px;
    padding-bottom: 15px;
    line-height: 24px;
    text-align: justify;
    p{
      margin: 0;
    }
    //&.show{
    //  display: block;
    //}
  }
}