.border-radius(@r: 6px) {
  -webkit-border-radius: @r;
  -moz-border-radius: @r;
  border-radius: @r;
}

.box-shadow(@x: 0, @y: 12px, @blur: 19px, @color: rgba(40, 4, 10, 0.5)){
  -webkit-box-shadow: @arguments;
  box-shadow: @arguments;
}

.linear-gradient(@deg: 135deg, @startColor: @wc, @endColor: #e3e7e8) {
  background-color: @startColor;
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(@deg, @startColor, @endColor);
  background: -moz-linear-gradient(@deg, @startColor, @endColor);
  background: -ms-linear-gradient(@deg, @startColor, @endColor);
  background: -o-linear-gradient(@deg, @startColor, @endColor);
}


.anime{
  //
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}

.growth_2_animate{
  &:after {
    animation-name: growth_2;
    animation-duration: 4s;
  }
  //animation-iteration-count: infinite;
}

@keyframes growth_2 {
  0%{
    background-image: url(images/growth/growth-1.png);
  }
  100%{
    background-image: url(images/growth/growth-2.png);
  }
}


.growth_3_animate{
  &:after {
    animation-name: growth_3;
    animation-duration: 4s;
    animation-delay: 1s;
  }
  //animation-iteration-count: infinite;
}

@keyframes growth_3 {
  0%{
    background-image: url(images/growth/growth-1.png);
  }
  50%{
    background-image: url(images/growth/growth-2.png);
  }
  100%{
    background-image: url(images/growth/growth-3.png);
  }
}


.growth_4_animate{
  &:after{
    animation-name: growth_4;
    animation-duration: 4s;
    animation-delay: 2s;
  }
  //animation-iteration-count: infinite;
}

@keyframes growth_4 {
  0%{
    background-image: url(images/growth/growth-1.png);
  }
  33%{
    background-image: url(images/growth/growth-2.png);
  }
  66%{
    background-image: url(images/growth/growth-3.png);
  }
  100%{
    background-image: url(images/growth/growth-4.png);
  }
}