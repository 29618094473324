//.container,
.btn-wrap{
  text-align: center;
}

//sign up btn
.sign-btn{
  background-color: @wc;
  font-family: @roboto;
  color:@bc;
  border: 1px solid @bc;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  min-width: 87px;
  padding: 10px 0;
  display: inline-block;
  .border-radius;
  &:hover,
  &:active{
    background-color: @bc;
    text-decoration: none;
    color: @wc;
  }
}

//log in btn
.log-btn{
  background-color: @bc;
  font-family: @roboto;
  color:@wc;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  min-width: 87px;
  padding: 10px 0;
  display: inline-block;
  .border-radius;

  @media all and  (min-width: @min-md){
    margin-left: 20px;
  }
  &:hover,
  &:active{
    background-color: @rc2;
    text-decoration: none;
    color: @wc;
  }
}

//btn red
.cta-1{
  font-family: @roboto;
  color:@wc;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  min-width: 226px;
  padding: 18px 0;
  display: inline-block;
  .border-radius(12px);
  .anime;
  .linear-gradient(0, @lg2, @lg1);
  .box-shadow(0, 12px, 19px, #7b1b29);
  &:hover{
    color:@wc;
    .linear-gradient(0, #87061e, #be0829);
    .box-shadow(0, 8px, 19px, #6D0312);
    text-decoration: none;
  }
  &:active{
    color:@wc;
    .box-shadow(0, 0, 3px, #6D0312);
  }
  @media all and  (max-width: @max-md){
    margin-top: 30px;
    margin-left: auto;
  }
}

//btn grey
.cta-2{
  .linear-gradient(to right, #d5d5d5, #ffffff);
  font-family: @roboto;
  color:@rc2;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  min-width: 226px;
  padding: 18px 0;
  display: inline-block;
  .border-radius(12px);
  .box-shadow;
  .anime;
  &:hover{
    .linear-gradient(to right, #d5d5d5, #f0f0f0);
    .box-shadow(0, 8px, 19px, rgba(40, 4, 10, 0.5));
    text-decoration: none;
  }
  &:active{
    .box-shadow(0, 0, 3px, rgba(40, 4, 10, 0.5));
  }
  @media all and  (max-width: @max-md){
    margin-top: 30px;
  }
}