.nav{
  font-family: @roboto;
  font-size: 16px;
  font-weight: 700;
  color: @rc2;
  text-transform: uppercase;
  text-align: center;
}
.menu-item{
  display: inline-block;
}