.number-wrap{
  @media all and  (min-width: @min-md){
    margin-top: 105px;
  }

  @media all and  (max-width: @max-md){
    margin-top: 30px;
  }
  .component-item:first-child{
    @media all and  (min-width: @min-md) {
      padding-right: 60px;
    }
    @media all and  (max-width: @max-md){
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 1080px) {
    margin-top: 50px;
    .number-content{
      flex-wrap: wrap;
      .number-item{
        display: block;
        width: 100%;
        margin-bottom: 50px;
      }
    }
  }
}

.number-item {
  display: inline-block;
  margin-bottom: 5px;

  @media screen and (min-width: @min-md) {
    &:first-child{
      margin-right: 70px;
    }
  }


  &__val {
    color: @rc2;
    font-size: 32px;
    font-weight: 700;
    @media all and  (max-width: @max-md) {
      font-size: 18px;
    }
  }

  &__text{
    color: @gc3;
    position: relative;
    font-weight: 400;
    font-size: 32px;
    @media all and  (max-width: @max-md){
      font-size: 18px;
    }
    &:after{
      content: '';
      display: inline-block;
      width: 75px;
      height: 95px;
      position: absolute;
      top: -27px;
      right: -45px;
      background-image: url('images/num-bg.png');
      background-repeat: no-repeat;
      background-position: 100% 50%;
      @media all and  (max-width: @max-md){
        display: none;
      }
    }
    @media all and  (max-width: @max-xs){
      //  font-size: 12px;
    }
  }
  @media screen and (min-width: 1280px) {
    &__text{
      font-size: 28px;
      &:after{
        top: -27px;
        right: -45px;
      }
    }
  }

}
