.copywriting {
  &-hiw {
    background-color: #f0f0f0;
    .component {
      &-subtitle {
        color: #000000;
        font-size: 23px;
        text-align: center;
        line-height: 35px;
        padding: 0 65px;
      }
    }
    .hiw-steps {
      align-items: inherit;
      margin-top: 90px;
      &-item {
        box-shadow: 6px 5px 22px rgba(0, 0, 0, 0.5);
        background-color: #ffffff;
        padding: 30px 10px 80px;
        box-sizing: border-box;
        width: 293px;
      }
      &-icon {
        min-height: 140px;
        &:before, &:after {
          display: none;
        }
      }
      &-title {
        font-size: 24px;
        min-height: 60px;
      }
      &-info {
        text-align: left;
        color: #717070;
        font-size: 16px;
        padding: 0 25px;
      }

    }
    @media (max-width: 992px){
      .component-subtitle {
        font-size: 20px;
        line-height: 30px;
      }
      .hiw-steps{
        display: block;
        margin-top: 50px;
        &-item{
          margin: 25px auto;
        }
      }
    }
    @media (max-width: 500px){
      .component-subtitle {
        padding: 0;
      }
    }
  }
  .cta-1{
    font-size: 20px;
  }
}