/* ----------------------------------------------------------------------
   LIBS
   ---------------------------------------------------------------------- */
.btn-wrap {
  text-align: center;
}
.sign-btn {
  background-color: #ffffff;
  font-family: 'Roboto Condensed', sans-serif;
  color: #000000;
  border: 1px solid #000000;
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  min-width: 87px;
  padding: 10px 0;
  display: inline-block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.sign-btn:hover,
.sign-btn:active {
  background-color: #000000;
  text-decoration: none;
  color: #ffffff;
}
.log-btn {
  background-color: #000000;
  font-family: 'Roboto Condensed', sans-serif;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  min-width: 87px;
  padding: 10px 0;
  display: inline-block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
@media all and (min-width: 992px) {
  .log-btn {
    margin-left: 20px;
  }
}
.log-btn:hover,
.log-btn:active {
  background-color: #b20c2a;
  text-decoration: none;
  color: #ffffff;
}
.cta-1 {
  font-family: 'Roboto Condensed', sans-serif;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  min-width: 226px;
  padding: 18px 0;
  display: inline-block;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  background-color: #9f031b;
  background: -webkit-gradient(linear, left top, left bottom, from(#9f031b), to(#f4505f));
  background: -webkit-linear-gradient(0, #9f031b, #f4505f);
  background: -moz-linear-gradient(0, #9f031b, #f4505f);
  background: -ms-linear-gradient(0, #9f031b, #f4505f);
  background: -o-linear-gradient(0, #9f031b, #f4505f);
  -webkit-box-shadow: 0 12px 19px #7b1b29;
  box-shadow: 0 12px 19px #7b1b29;
}
.cta-1:hover {
  color: #ffffff;
  background-color: #87061e;
  background: -webkit-gradient(linear, left top, left bottom, from(#87061e), to(#be0829));
  background: -webkit-linear-gradient(0, #87061e, #be0829);
  background: -moz-linear-gradient(0, #87061e, #be0829);
  background: -ms-linear-gradient(0, #87061e, #be0829);
  background: -o-linear-gradient(0, #87061e, #be0829);
  -webkit-box-shadow: 0 8px 19px #6D0312;
  box-shadow: 0 8px 19px #6D0312;
  text-decoration: none;
}
.cta-1:active {
  color: #ffffff;
  -webkit-box-shadow: 0 0 3px #6D0312;
  box-shadow: 0 0 3px #6D0312;
}
@media all and (max-width: 991px) {
  .cta-1 {
    margin-top: 30px;
    margin-left: auto;
  }
}
.cta-2 {
  background-color: #d5d5d5;
  background: -webkit-gradient(linear, left top, left bottom, from(#d5d5d5), to(#ffffff));
  background: -webkit-linear-gradient(to right, #d5d5d5, #ffffff);
  background: -moz-linear-gradient(to right, #d5d5d5, #ffffff);
  background: -ms-linear-gradient(to right, #d5d5d5, #ffffff);
  background: -o-linear-gradient(to right, #d5d5d5, #ffffff);
  font-family: 'Roboto Condensed', sans-serif;
  color: #b20c2a;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;
  min-width: 226px;
  padding: 18px 0;
  display: inline-block;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: 0 12px 19px rgba(40, 4, 10, 0.5);
  box-shadow: 0 12px 19px rgba(40, 4, 10, 0.5);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.cta-2:hover {
  background-color: #d5d5d5;
  background: -webkit-gradient(linear, left top, left bottom, from(#d5d5d5), to(#f0f0f0));
  background: -webkit-linear-gradient(to right, #d5d5d5, #f0f0f0);
  background: -moz-linear-gradient(to right, #d5d5d5, #f0f0f0);
  background: -ms-linear-gradient(to right, #d5d5d5, #f0f0f0);
  background: -o-linear-gradient(to right, #d5d5d5, #f0f0f0);
  -webkit-box-shadow: 0 8px 19px rgba(40, 4, 10, 0.5);
  box-shadow: 0 8px 19px rgba(40, 4, 10, 0.5);
  text-decoration: none;
}
.cta-2:active {
  -webkit-box-shadow: 0 0 3px rgba(40, 4, 10, 0.5);
  box-shadow: 0 0 3px rgba(40, 4, 10, 0.5);
}
@media all and (max-width: 991px) {
  .cta-2 {
    margin-top: 30px;
  }
}
.flex {
  display: flex;
}
.flex-ai-c {
  align-items: center;
}
.flex-ai-s {
  align-items: stretch;
}
.flex-ai-flEnd {
  align-items: flex-end;
}
.flex-ai-flStar {
  align-items: flex-start;
}
.flex-jc-c {
  justify-content: center;
}
.flex-jc-spBet {
  justify-content: space-between;
}
.flex-jc-spAr {
  justify-content: space-around;
}
.flex-jc-flStar {
  justify-content: flex-start;
}
.flex-jc-flEnd {
  justify-content: flex-end;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-ac-flStar {
  align-content: flex-start;
}
@media all and (max-width: 991px) {
  .flex {
    flex-direction: column;
  }
}
/* roboto */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCoYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-33mZGCQYb9lecyU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DQk6YvNkeg.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCoYb9lecyVC4A.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'), url(https://fonts.gstatic.com/s/robotocondensed/v16/ieVi2ZhZI2eCN5jzbjEETS9weq8-32meGCQYb9lecyU.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* play */
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: local('Play Regular'), local('Play-Regular'), url(https://fonts.gstatic.com/s/play/v9/6aez4K2oVqwIvts2H70Q13HqPA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Play';
  font-style: normal;
  font-weight: 400;
  src: local('Play Regular'), local('Play-Regular'), url(https://fonts.gstatic.com/s/play/v9/6aez4K2oVqwIvtU2H70Q13E.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lWAe5j5hNKe1_w.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 700;
  src: local('Playfair Display Bold'), local('PlayfairDisplay-Bold'), url(https://fonts.gstatic.com/s/playfairdisplay/v13/nuFlD-vYSZviVYUb_rj3ij__anPXBYf9lW4e5j5hNKc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.anime {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.growth_2_animate:after {
  animation-name: growth_2;
  animation-duration: 4s;
}
@keyframes growth_2 {
  0% {
    background-image: url(images/growth/growth-1.png);
  }
  100% {
    background-image: url(images/growth/growth-2.png);
  }
}
.growth_3_animate:after {
  animation-name: growth_3;
  animation-duration: 4s;
  animation-delay: 1s;
}
@keyframes growth_3 {
  0% {
    background-image: url(images/growth/growth-1.png);
  }
  50% {
    background-image: url(images/growth/growth-2.png);
  }
  100% {
    background-image: url(images/growth/growth-3.png);
  }
}
.growth_4_animate:after {
  animation-name: growth_4;
  animation-duration: 4s;
  animation-delay: 2s;
}
@keyframes growth_4 {
  0% {
    background-image: url(images/growth/growth-1.png);
  }
  33% {
    background-image: url(images/growth/growth-2.png);
  }
  66% {
    background-image: url(images/growth/growth-3.png);
  }
  100% {
    background-image: url(images/growth/growth-4.png);
  }
}
/* ----------------------------------------------------------------------
   RESET
   ---------------------------------------------------------------------- */
:focus {
  outline: none;
}
html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #636a76;
}
@media all and (max-width: 1199px) {
  body {
    font-size: 14px;
  }
}
h1,
h2,
h3,
.h1,
.h2,
.h3,
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  color: #000000;
}
ul {
  padding: 0;
  list-style-position: inside;
}
a {
  outline: none;
  color: #636a76;
  text-decoration: none;
}
a:hover {
  color: #b20c2a;
}
a:active {
  color: #8a1328;
}
img {
  max-width: 100%;
  height: auto;
}
.hidden {
  display: none;
}
/* ----------------------------------------------------------------------
   LAYOUT
   ---------------------------------------------------------------------- */
article h1,
article h2,
article h3 {
  color: #636a76;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.menu {
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
}
.menu li {
  display: inline-block;
  list-style: none;
  padding: 0 23px;
}
.menu li:last-child {
  padding-right: 0;
}
.menu li.current-menu-item a::after {
  background: #9499a5;
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .menu li {
    padding: 0 10px;
  }
}
.menu a {
  outline: none;
  color: #9499a5;
  font-weight: 400;
  text-decoration: none;
  display: block;
}
.menu a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  transition: width .3s;
}
@media screen and (min-width: 768px) {
  .menu a:hover::after {
    background: #9499a5;
    width: 100%;
  }
}
.menu a:active {
  color: #636a76;
}
#menu-header-menu li.current-menu-item a::after {
  background: #8a1328;
  width: 100%;
}
#menu-header-menu a {
  color: #b20c2a;
}
#menu-header-menu a:hover::after {
  background: #b20c2a;
  width: 100%;
}
#menu-header-menu a:active {
  color: #8a1328;
}
.component-wrap {
  position: relative;
  max-width: 1160px;
  margin: auto;
  z-index: 1;
}
@media all and (max-width: 1199px) {
  .component-wrap {
    padding: 0 15px;
  }
}
@media all and (max-width: 767px) {
  .component-wrap {
    display: block;
  }
}
.component-item {
  flex: 1 0 50%;
}
@media screen and (max-width: 991px) {
  .component-item_blocks {
    width: 100%;
  }
}
.component-title-white {
  text-align: left;
  font-size: 48px;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
  position: relative;
}
@media all and (max-width: 1199px) {
  .component-title-white br {
    display: none;
  }
}
@media all and (max-width: 991px) {
  .component-title-white {
    font-size: 36px;
  }
}
.component-subtitle-white {
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
}
@media all and (max-width: 1199px) {
  .component-subtitle-white br {
    display: none;
  }
}
@media all and (max-width: 991px) {
  .component-subtitle-white {
    font-size: 14px;
    padding: 0 5px;
  }
}
.component-title {
  text-align: left;
  font-size: 48px;
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  color: #000000;
  margin-bottom: 25px;
  position: relative;
}
@media all and (max-width: 1199px) {
  .component-title br {
    display: none;
  }
}
@media all and (max-width: 991px) {
  .component-title {
    font-size: 36px;
  }
}
.component-subtitle {
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  color: #636a76;
  line-height: 24px;
}
@media all and (max-width: 1199px) {
  .component-subtitle br {
    display: none;
  }
}
@media all and (max-width: 991px) {
  .component-subtitle {
    font-size: 14px;
    padding: 0 5px;
  }
}
input {
  color: #000000;
}
@media all and (min-width: 992px) {
  .pagingInfo {
    display: none;
  }
}
@media all and (max-width: 768px) {
  .slider .slider__navigation_light .slick-arrow:active,
  .slider .slider__navigation_light .slick-arrow:hover {
    color: #fff;
  }
}
@media all and (max-width: 768px) {
  .slider .slider__navigation_dark .slick-arrow:active,
  .slider .slider__navigation_dark .slick-arrow:hover {
    color: #8a1328;
  }
}
@media all and (min-width: 768px) {
  .slider__navigation_vert .pagingInfo {
    display: none;
  }
}
.seo-wrap p,
.seo-wrap ul > li,
.seo-wrap ol > li {
  line-height: 24px;
}
.light-bg {
  background-color: #ffffff;
}
.light-bg .header-wrap {
  padding: 15px 0 !important;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
}
#cookieMargin {
  transition: 0.05s;
}
#mia-chat-btn-open svg {
  margin-top: 11px!important;
}
#mia-chat-btn-close svg {
  margin-top: 10px!important;
}
#mia-chat-button {
  width: 58px !important;
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* ----------------------------------------------------------------------
   VARIABLES
   ---------------------------------------------------------------------- */
@media all and (max-width: 991px) {
  .article,
  .page__content {
    padding: 40px 0;
  }
}
@media all and (min-width: 992px) and (max-width: 1199px) {
  .article,
  .page__content {
    padding: 50px 0;
  }
}
@media all and (min-width: 1200px) {
  .article,
  .page__content {
    padding: 80px 0;
  }
}
#slider-1 .slick-slide img,
#slider-2 .slick-slide img,
#slider-3 .slick-slide img,
#slider-4 .slick-slide img,
#slider-5 .slick-slide img,
#slider-6 .slick-slide img {
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .slider__navigation {
    text-align: center;
    margin-top: 20px;
    width: 100%;
  }
  .slider__navigation .slick-arrow {
    padding: 0 35px;
    text-align: center;
    font-size: 24px;
    border: none;
    background: none;
  }
  .slider__navigation .slick-arrow:hover {
    color: #c91d3c;
  }
  .slider__navigation .slick-arrow:active {
    color: #8a1328;
  }
}
.slider__navigation_sm {
  display: none;
}
.slick-initialized .slider__navigation_sm {
  display: block;
}
.slider__navigation_light .slick-arrow {
  color: #fff;
}
.slider__navigation_light .slick-arrow.slick-disabled {
  color: #e68d9c;
}
.slider__navigation_light .pagingInfo {
  color: #e68d9c;
}
.slider__navigation_light .pagingInfo > span {
  color: #fff;
}
.slider__navigation_dark .slick-arrow {
  color: #c91d3c;
}
.slider__navigation_dark .slick-arrow.slick-disabled {
  color: #e48e9d;
}
.slider__navigation_dark .pagingInfo {
  color: #9499a5;
}
.slider__navigation_dark .pagingInfo > span {
  color: #000;
}
@media all and (max-width: 768px) {
  .slider-5__navigation {
    margin-top: 20px;
  }
}
.pagingInfo {
  font-size: 18px;
}
/* ----------------------------------------------------------------------
  HEADER
   ---------------------------------------------------------------------- */
.header {
  transition: background-color 0.3s;
  transition: 0.05s;
}
.header-wrap {
  padding: 12px 0;
  position: relative;
  align-items: flex-start;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media all and (max-width: 1199px) {
  .header-wrap {
    flex-direction: row;
    padding: 15px;
  }
}
@media all and (max-width: 768px) {
}
.header .btn-item {
  text-align: right;
}
@media all and (min-width: 992px) and (max-width: 991px) {
  .header .btn-item {
    display: none;
  }
}
@media all and (max-width: 767px) {
  .header .btn-item a {
    display: block;
    max-width: 180px;
    margin: auto;
    margin-top: 20px;
  }
}
.header .logo-link {
  position: relative;
  display: inline-block;
  z-index: 1000;
}
@media all and (max-width: 766px) {
  .header .logo-link {
    display: inline-block;
  }
}
.header .phone {
  max-width: 166px;
  max-height: 21px;
}
.header-wrap-menu {
  text-align: right;
  padding: 25px 0;
}
@media all and (max-width: 1199px) {
  .header-wrap-menu {
    padding: 25px 15px;
  }
}
@media all and (max-width: 767px) {
  .header-wrap-menu {
    display: none;
  }
}
@media all and (min-width: 1200px) {
  .header .flex-first {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.header.scrollr {
  background-color: rgba(255, 255, 255, 0.9);
}
.header.scrollr .header-wrap {
  padding: 12px 0;
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
@media all and (max-width: 1199px) {
  .header.scrollr .header-wrap {
    padding: 15px;
  }
}
@media all and (max-width: 767px) {
  .header.scrollr .header-wrap {
    padding: 15px 15px 0;
  }
  .header.scrollr .header-wrap .header-item_logo {
    border-bottom: none;
  }
}
.header.scrollr .header-wrap-menu {
  display: none;
}
.header.mmenu-open {
  background-color: rgba(255, 255, 255, 0.97);
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.97)), to(rgba(227, 231, 232, 0.97)));
  background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 0.97), rgba(227, 231, 232, 0.97));
  background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 0.97), rgba(227, 231, 232, 0.97));
  background: -ms-linear-gradient(90deg, rgba(255, 255, 255, 0.97), rgba(227, 231, 232, 0.97));
  background: -o-linear-gradient(90deg, rgba(255, 255, 255, 0.97), rgba(227, 231, 232, 0.97));
}
.header-item.header-item_logo {
  flex: 0 0 105px;
  padding: 3px 25px 6px 0;
}
.header-item.header-item_phone {
  flex: 1 0 235px;
}
.header-item.header-item_menu {
  flex: 1 0 auto;
  transition: opacity 0.4s;
}
@media all and (max-width: 767px) {
  .header-item.header-item_logo {
    text-align: center;
  }
}
@media all and (min-width: 768px) {
  .header-item.header-item_phone {
    display: none;
  }
  .header-item.header-item_menu {
    display: inline-block;
    margin-right: 20px;
  }
}
@media all and (min-width: 992px) {
  .header-item.header-item_logo {
    margin-right: 35px;
    border-right: 1px solid #d7d7d7;
  }
  .header-item.header-item_menu {
    display: block;
    margin-right: 30px;
  }
}
@media all and (min-width: 1200px) {
  .header-item.header-item_phone {
    display: block;
  }
  .header-item.header-item_menu {
    display: block;
    margin-right: 0;
  }
}
@media all and (min-width: 1200px) {
  .header_main_page .header-item_menu {
    order: 3;
    flex: 1 0 100%;
    padding-top: 30px;
    justify-content: flex-end;
    text-align: right;
  }
  .header_main_page.scrollr .header-item_menu {
    order: 0;
    flex: 1 0 auto;
    padding-top: 0;
    animation: header-jump 1s;
    margin-right: 40px;
  }
  .header_main_page.is-init .header-item_menu {
    animation: header-jump2 1s;
  }
}
.main-nav a {
  color: #b20c2a;
  font-family: 'Roboto Condensed', sans-serif, sans-serif;
  font-size: 16px;
}
/*HEADER: SLICKNAV MENU*/
.mobile-nav {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  height: 55px;
}
@media all and (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}
.header-items-wrap {
  flex: 1 0 auto;
  align-self: center;
}
@media all and (max-width: 767px) {
  .header-items-wrap {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header-items {
    overflow: auto;
    height: 100%;
    min-height: 100%;
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 768px) {
  .header-items {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-flow: row wrap;
    width: 100%;
    height: 38px;
  }
}
.slicknav_menu {
  display: inline-block;
  float: right;
}
.slicknav_menu > a {
  position: relative;
  display: inline-block;
  height: 30px;
  padding: 5px 13px;
}
.slicknav_menu .menu-item {
  padding: 0;
}
.slicknav_open .slicknav_icon-bar:first-child {
  transform: rotate(45deg);
  position: relative;
  top: 10px;
}
.slicknav_open .slicknav_icon-bar:nth-child(2) {
  display: none;
}
.slicknav_open .slicknav_icon-bar:last-child {
  transform: rotate(-45deg);
  position: relative;
  top: 3px;
}
.slicknav_btn {
  font: 700 20px/20px 'Roboto Condensed', sans-serif, sans-serif;
  z-index: 10000000;
}
.slicknav_menutxt,
.slicknav_icon {
  float: left;
}
.slicknav_menutxt {
  display: none;
}
.slicknav_icon-bar {
  display: block;
  font: 700 16px/20px 'Roboto Condensed', sans-serif, sans-serif;
  width: 30px;
  height: 2px;
  margin: 5px 0;
  background: #636a76;
}
.slicknav_nav {
  position: fixed;
  transition: 0.09s;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  margin: 0;
  padding: 15px 0 30px;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.95);
}
.slicknav_nav ul {
  margin: 0;
}
.slicknav_nav li {
  display: block;
}
.slicknav_nav li a {
  display: block;
  padding: 10px;
  color: #b20c2a;
  font-size: 20px;
}
.slicknav_nav .phone-link {
  position: relative;
  display: block;
  margin: 80px 0 15px;
}
@media only screen and (max-width: 767px) {
  .slicknav_nav .phone-link {
    display: inline-block;
    background: url("images/phone-img.png") no-repeat;
    width: 166px;
    height: 21px;
  }
  .slicknav_nav .phone-link svg {
    display: none;
  }
}
.mobile-btn {
  display: none;
  position: absolute;
  top: 20px;
  right: 5px;
  width: 30px;
  height: 20px;
}
@media all and (max-width: 767px) {
  .mobile-btn {
    display: inline-block;
  }
}
@media all and (max-width: 767px) {
  .header .header-item_logo {
    position: relative;
    z-index: 11;
    padding: 3px 0 15px 0;
    border-bottom: 1px solid #d7d7d7;
  }
  .header .header-item_phone {
    padding: 15px 0;
    text-align: center;
  }
  .header .header-item.header-item_menu {
    margin-right: 0;
  }
  .header .main-nav {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid #d7d7d7;
  }
  .header .menu-item {
    margin-bottom: 25px;
  }
}
body.menu-is-open {
  overflow: hidden;
}
body.menu-is-open .header-item_logo {
  background-color: rgba(255, 255, 255, 0.95);
}
@keyframes header-jump {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
@keyframes header-jump2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
.nav {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #b20c2a;
  text-transform: uppercase;
  text-align: center;
}
.menu-item {
  display: inline-block;
}
/* ----------------------------------------------------------------------
  BANNER
   ---------------------------------------------------------------------- */
@media all and (max-width: 991px) {
  .main-screen {
    text-align: center;
  }
}
@media all and (min-width: 992px) {
  .main-screen .component-title,
  .main-screen .component-subtitle {
    text-align: left;
  }
}
.main-screen .component-title {
  font-family: 'Playfair Display', serif;
  color: #000000;
  font-size: 60px;
  margin-bottom: 25px;
}
@media all and (max-width: 991px) {
  .main-screen .component-title {
    font-size: 45px;
  }
}
@media all and (max-width: 767px) {
  .main-screen .component-title {
    line-height: 50px;
    margin-bottom: 10px;
  }
}
.main-screen .component-subtitle {
  font-size: 32px;
  font-weight: 400;
  line-height: 23px;
}
@media all and (max-width: 1280px) {
  .main-screen .component-subtitle {
    font-size: 28px;
  }
}
@media all and (max-width: 1080px) {
  .main-screen .component-subtitle {
    width: 40%;
    line-height: 30px;
  }
}
@media all and (max-width: 991px) {
  .main-screen .component-subtitle {
    width: 100%;
    font-size: 18px;
  }
}
.privacyCookie {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  text-align: center;
  font-size: 12px;
  font-family: Arial, sans-serif;
  padding: 10px 0;
  background: #372370;
  box-sizing: border-box;
}
@media screen and (max-width: 1148px) {
  .privacyCookie {
    padding: 10px 15px;
  }
}
.privacyCookie .inline {
  display: inline;
  color: #fff;
  line-height: 1.42857143;
}
.privacyCookie a {
  color: #fff;
  text-decoration: underline;
}
.privacyCookie button {
  cursor: pointer;
  text-transform: capitalize;
  font-size: 13px;
  color: #372370;
  background: #ffffff;
  border: 0;
  border-radius: 4px;
  padding: 4px 15px;
  display: inline-block;
  margin-left: 15px;
  line-height: normal;
}
@media screen and (max-width: 1148px) {
  .privacyCookie button {
    display: block;
    min-width: 150px;
    margin: 10px auto 0 auto;
  }
}
@media all and (max-width: 991px) {
  .page-id-1045 .page__content,
  .page-id-1047 .page__content {
    padding: 70px 0 40px;
  }
}
.main-screen {
  background: url(images/main-bg.png) no-repeat 82% 64%, linear-gradient(137deg, #e3e7e8 0%, #ffffff 50%);
  position: relative;
}
.main-screen.pay-rates {
  background: linear-gradient(137deg, #e3e7e8, #ffffff);
}
.main-screen.pay-rates .main-screen-content {
  padding-top: 175px;
  padding-bottom: 110px;
  text-align: left;
}
.main-screen.pay-rates .main-screen-content:after {
  content: '';
  background: url(images/pr-img.png) no-repeat 100% 90%;
  position: absolute;
  bottom: 5%;
  right: -20px;
  width: 590px;
  height: 410px;
  display: block;
  z-index: -1;
}
.main-screen.pay-rates .main-screen-content .component-title,
.main-screen.pay-rates .main-screen-content .component-subtitle {
  text-align: left;
}
@media all and (max-width: 1198px) {
  .main-screen.pay-rates .main-screen-content:after {
    right: 0;
  }
}
@media all and (max-width: 768px) {
  .main-screen.pay-rates .main-screen-content {
    padding-top: 100px;
    padding-bottom: 0px;
    text-align: center;
  }
  .main-screen.pay-rates .main-screen-content:after {
    position: relative;
    right: 0;
    margin: auto;
    width: 300px;
    height: 210px;
    background-size: 100% 100%;
    background-position: 50% 100%;
    margin-top: 60px;
  }
  .main-screen.pay-rates .main-screen-content .component-title,
  .main-screen.pay-rates .main-screen-content .component-subtitle {
    text-align: center;
  }
}
.main-screen.hts {
  background: linear-gradient(137deg, #e3e7e8, #ffffff);
}
.main-screen.hts .main-screen-content {
  padding-top: 175px;
  padding-bottom: 110px;
}
.main-screen.hts .main-screen-content:after {
  content: '';
  background: url(images/hts-img.png) no-repeat 100% 90%;
  position: absolute;
  bottom: 5%;
  right: -75px;
  width: 650px;
  height: 410px;
  display: block;
}
@media all and (max-width: 1198px) {
  .main-screen.hts .main-screen-content:after {
    right: 0;
  }
}
@media all and (max-width: 768px) {
  .main-screen.hts .main-screen-content {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .main-screen.hts .main-screen-content:after {
    position: relative;
    right: 0;
    margin: auto;
    width: 300px;
    height: 210px;
    background-size: 100% 100%;
    background-position: 50% 100%;
    margin-top: 60px;
  }
}
@media screen and (max-width: 1440px) {
  .main-screen {
    background: url(images/main-bg.png) no-repeat 100% 64%, linear-gradient(137deg, #e3e7e8 0%, #ffffff 50%);
  }
}
@media screen and (max-width: 991px) {
  .main-screen {
    background: url(images/banner-mob.png) no-repeat 50% 100%, linear-gradient(137deg, #e3e7e8, #ffffff);
  }
}
@media all and (min-width: 1000px) {
  .main-screen-content {
    padding-top: 130px;
    padding-bottom: 175px;
  }
}
@media all and (min-width: 1040px) {
  .main-screen-content {
    padding-top: 230px;
    padding-bottom: 185px;
  }
}
@media all and (max-width: 992px) {
  .main-screen-content {
    padding-top: 125px;
    padding-bottom: 280px;
  }
  .main-screen-content .component-title,
  .main-screen-content .component-subtitle {
    text-align: center;
  }
}
@media all and (min-width: 992px) {
  .main-screen .cta-1 {
    margin-top: 55px;
  }
}
.animation .academia {
  position: absolute;
  right: 19.8%;
  top: 20px;
  z-index: 1;
}
.animation .academia .letter {
  color: #d1dee3;
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  padding: 100px 50px;
}
.animation .academia .letter__1 {
  transform: rotate(-19deg);
  right: 420px;
  top: 110px;
}
.animation .academia .letter__2 {
  right: 346px;
  top: 107px;
}
.animation .academia .letter__3 {
  transform: rotate(23deg);
  top: 40px;
  right: 307px;
}
.animation .academia .letter__4 {
  transform: rotate(-12deg);
  top: 63px;
  right: 190px;
}
.animation .academia .letter__5 {
  top: 112px;
  right: 117px;
}
.animation .academia .letter__6 {
  transform: rotate(28deg);
  top: 62px;
  right: 20px;
}
.animation .academia .letter__7 {
  top: 107px;
  right: -62px;
}
.animation .academia .letter__8 {
  top: 150px;
  right: -110px;
}
.animation .picture img {
  transition: all 0.5s ease-in-out;
  position: absolute;
}
.animation .picture img.picture__boy {
  width: 55px;
  top: 34.2%;
  right: 23.5%;
  animation: main-picture_boy 5s linear alternate infinite;
}
.animation .picture img.picture__stairs {
  width: 125px;
  top: 42.2%;
  right: 23%;
  transform-origin: bottom right;
  animation: main-picture_stairs 5s linear alternate infinite;
}
.animation .picture img.picture__girl {
  width: 65px;
  top: 62%;
  right: 27.8%;
  transform-origin: bottom right;
  animation: main-picture_girl 5s linear alternate infinite;
  transform: rotate(-3deg);
}
.animation .research {
  text-align: right;
  position: absolute;
  bottom: 45px;
  width: 100%;
  right: 15%;
}
@keyframes main-picture_boy {
  0%,
  15% {
    transform: rotate(4deg) translateX(10px);
  }
  25%,
  100% {
    transform: rotate(-1deg) translateX(-2px);
  }
}
@keyframes main-picture_stairs {
  0%,
  15% {
    transform: rotate(1deg);
  }
  25%,
  100% {
    transform: rotate(-1deg);
  }
}
@keyframes main-picture_girl {
  0% {
    transform: rotate(-1deg);
  }
  10% {
    transform: rotate(-1deg);
  }
  15% {
    transform: rotate(-3deg);
  }
  25% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}
@media screen and (max-width: 1600px) {
  .animation .picture img.picture__girl {
    right: 28.8%;
  }
}
@media screen and (max-width: 1440px) {
  .animation .picture img.picture__boy {
    right: 15.7%;
  }
  .animation .picture img.picture__stairs {
    right: 15%;
  }
  .animation .picture img.picture__girl {
    right: 21.5%;
  }
  .animation .academia {
    right: 10%;
  }
  .animation .research {
    right: 4%;
  }
}
@media screen and (max-width: 1280px) {
  .animation .picture img.picture__girl {
    right: 22%;
  }
}
@media screen and (max-width: 1199px) {
  .animation .picture img.picture__girl {
    right: 31.7%;
  }
}
@media screen and (max-width: 1080px) {
  .animation .picture img.picture__boy {
    right: 20.7%;
  }
  .animation .picture img.picture__stairs {
    right: 20%;
  }
  .animation .picture img.picture__girl {
    right: 29%;
  }
  .animation .academia {
    right: 15%;
  }
}
@media screen and (max-width: 991px) {
  .animation {
    display: none;
  }
}
/*-------------------
  HOVER ICON ANIMATE
--------------------*/
#Path-2,
#Onboarding,
#Path-21 {
  transition: all 4s ease-in-out;
}
.dollar,
.transform {
  transition: all 0.5s ease-in-out;
}
@keyframes transform {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-14deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(14deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
/* ----------------------------------------------------------------------
  FOOTER
   ---------------------------------------------------------------------- */
.footer-wrap {
  background-color: #ffffff;
  font-size: 14px;
  padding: 80px 0 30px;
}
@media all and (max-width: 991px) {
  .footer-wrap {
    text-align: center;
    padding: 20px 0;
  }
}
.footer-wrap .flex-first,
.footer-wrap .flex-last {
  flex: 1 0 33%;
}
@media all and (max-width: 767px) {
  .footer-wrap .flex-first,
  .footer-wrap .flex-last {
    margin-bottom: 10px;
  }
}
@media all and (min-width: 992px) {
  .footer-wrap .flex-last {
    text-align: right;
  }
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo .logo-link {
  display: inline-block;
}
.footer-logo .logo-link svg {
  max-width: 100px;
  max-height: 40px;
}
@media all and (max-width: 991px) {
  .footer-logo {
    text-align: center;
    margin-bottom: 10px;
  }
}
.footer-phone {
  margin-bottom: 5px;
}
.footer-phone svg {
  max-width: 147px;
  max-height: 20px;
}
@media all and (max-width: 579px) {
  .footer-phone {
    text-align: center;
    margin-bottom: 20px;
  }
}
.footer-wrap .fb {
  margin-top: 20px;
}
.footer-copy {
  margin-top: 30px;
  font-size: 10px;
}
@media all and (max-width: 991px) {
  .footer-copy {
    margin-top: 20px;
  }
}
.footer-links {
  margin-top: 45px;
}
@media all and (max-width: 991px) {
  .footer-links {
    margin-top: 15px;
  }
}
.footer-links .country-spain,
.footer-links .country-france {
  width: 25px;
  height: 25px;
  margin-top: 2px;
}
.footer-links ul {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
@media all and (max-width: 991px) {
  .footer-links ul {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.footer-links li {
  list-style: none;
  padding: 0 5px;
}
@media all and (max-width: 991px) {
  .footer-links li {
    padding: 10px 5px;
    flex: 1 0 20%;
  }
}
#menu-footer {
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 991px) {
  #menu-footer {
    flex-direction: column;
  }
}
#menu-footer li {
  flex: 0 0 33%;
  padding: 10px 0;
}
@media all and (max-width: 991px) {
  #menu-footer li {
    font-size: 16px;
    flex: 1 0 100%;
  }
}
#menu-footer a {
  color: #9499a5;
  display: inline-block;
}
.footer-info {
  font-size: 10px;
  padding: 40px 20px 0;
  text-align: center;
  color: #9499a5;
}
/* ----------------------------------------------------------------------
  NUMBERS
   ---------------------------------------------------------------------- */
@media all and (min-width: 992px) {
  .number-wrap {
    margin-top: 105px;
  }
}
@media all and (max-width: 991px) {
  .number-wrap {
    margin-top: 30px;
  }
}
@media all and (min-width: 992px) {
  .number-wrap .component-item:first-child {
    padding-right: 60px;
  }
}
@media all and (max-width: 991px) {
  .number-wrap .component-item:first-child {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1080px) {
  .number-wrap {
    margin-top: 50px;
  }
  .number-wrap .number-content {
    flex-wrap: wrap;
  }
  .number-wrap .number-content .number-item {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
}
.number-item {
  display: inline-block;
  margin-bottom: 5px;
}
@media screen and (min-width: 992px) {
  .number-item:first-child {
    margin-right: 70px;
  }
}
.number-item__val {
  color: #b20c2a;
  font-size: 32px;
  font-weight: 700;
}
@media all and (max-width: 991px) {
  .number-item__val {
    font-size: 18px;
  }
}
.number-item__text {
  color: #636a76;
  position: relative;
  font-weight: 400;
  font-size: 32px;
}
@media all and (max-width: 991px) {
  .number-item__text {
    font-size: 18px;
  }
}
.number-item__text:after {
  content: '';
  display: inline-block;
  width: 75px;
  height: 95px;
  position: absolute;
  top: -27px;
  right: -45px;
  background-image: url('images/num-bg.png');
  background-repeat: no-repeat;
  background-position: 100% 50%;
}
@media all and (max-width: 991px) {
  .number-item__text:after {
    display: none;
  }
}
@media all and (max-width: 579px) {
}
@media screen and (min-width: 1280px) {
  .number-item__text {
    font-size: 28px;
  }
  .number-item__text:after {
    top: -27px;
    right: -45px;
  }
}
/* ----------------------------------------------------------------------
  TESTIMONIALS
   ---------------------------------------------------------------------- */
.testimonials-wrap {
  background-image: url(images/oval-bg.png), linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
  background-size: cover !important;
  padding: 50px 0;
}
.testimonials-wrap .component-wrap {
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.testimonials-wrap .slider__navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  width: auto;
}
@media all and (max-width: 767px) {
  .testimonials-wrap .slider__navigation {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .testimonials-wrap .slider__navigation {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
  .testimonials-wrap .slider__navigation_flex {
    display: flex;
    max-width: 240px;
    margin: 20px auto 0;
  }
  .testimonials-wrap .slider__navigation_flex .slider-arrow_next,
  .testimonials-wrap .slider__navigation_flex .slider-arrow_prev {
    transform: rotate(-90deg);
  }
}
.testimonials-wrap .sitejabber-icon {
  display: inline-block;
  margin-top: 45px;
}
@media only screen and (max-width: 767px) {
  .testimonials-wrap .sitejabber-icon {
    margin-top: 20px;
  }
}
.testimonials-wrap .component-item:first-child {
  flex: 0 0 35%;
}
@media all and (max-width: 991px) {
  .testimonials-wrap .component-item:first-child {
    margin-bottom: 20px;
    text-align: center;
  }
}
@media all and (max-width: 991px) {
  .testimonials-wrap .component-item:first-child .component-title-white {
    text-align: center;
  }
}
@media all and (max-width: 767px) {
  .testimonials-wrap .component-item:first-child {
    flex: 1 0 auto;
  }
  .testimonials-wrap .component-item:first-child .component-title-white {
    text-align: center;
  }
}
.testimonials-wrap .component-item:last-child {
  position: relative;
  padding-left: 70px;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .testimonials-wrap .component-item:last-child {
    height: 504px;
  }
}
@media (min-width: 992px) {
  .testimonials-wrap .component-item:last-child {
    height: 550px;
  }
}
@media all and (min-width: 992px) {
  .testimonials-wrap .component-item:last-child .slider__navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 767px) {
  .testimonials-wrap .component-item:last-child {
    padding-left: 0;
  }
}
.testimonials-wrap svg {
  max-width: 72px;
  max-height: 72px;
}
.testimonials-wrap .slick-arrow {
  display: block !important;
  background-color: transparent;
  text-align: center;
  margin: auto;
  color: #ffffff;
  font-size: 24px;
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  border: none;
  padding: 10px;
}
.testimonials-wrap .slick-arrow:hover {
  color: #9499a5;
}
@media all and (max-width: 767px) {
  .testimonials-wrap .slick-arrow {
    display: inline-block !important;
    padding: 0 15px;
  }
  .testimonials-wrap .slick-arrow:hover {
    color: #fff;
  }
}
.testimonials-wrap .slick-arrow.slick-disabled {
  color: #9499a5;
}
.testimonials-wrap .slick-dots {
  text-align: center;
  list-style: none;
}
@media all and (min-width: 768px) {
  .testimonials-wrap .slick-dots {
    margin-bottom: 45px;
  }
}
.testimonials-wrap .slick-dots li {
  display: inline-block;
}
.testimonials-wrap .slick-dots li button {
  font-size: 0;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  margin: 6px;
  cursor: pointer;
  transition: .25s;
}
.testimonials-wrap .slick-dots li.slick-active button {
  background-color: transparent;
  transform: scale(1.5);
}
.testimonials-list {
  overflow: hidden;
  /* the parent */
}
.testimonials-list .slick-slide {
  padding: 5px 0;
  border: none;
}
@media all and (max-width: 767px) {
  .testimonials-list .slick-slide {
    margin: 0 10px;
  }
}
.testimonials-list .slick-list {
  padding: -5px 0;
  position: relative;
}
.testimonials-inner {
  padding: 35px 15px 35px 20px;
  background: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.testimonials-icon {
  min-height: 80px;
}
@media all and (min-width: 768px) {
  .testimonials-icon {
    display: inline-block;
    width: 12%;
    padding-right: 25px;
  }
}
@media all and (max-width: 767px) {
  .testimonials-icon {
    text-align: center;
  }
}
.testimonials-icon img {
  display: inline-block;
}
@media all and (min-width: 768px) {
  .testimonials-info {
    display: inline-block;
    width: 78%;
  }
}
.testimonials-text {
  line-height: 24px;
  margin-bottom: 15px;
}
.testimonials-text:before {
  content: '\f10d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 24px;
  color: #b20c2a;
  padding-right: 5px;
}
.testimonials-title {
  color: #000000;
}
.testimonials-about {
  color: #636a76;
}
/* ----------------------------------------------------------------------
  ABOUT US
   ---------------------------------------------------------------------- */
.about-wrap {
  background-image: url(images/oval-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
  background-position: 50% 0;
  background-size: cover !important;
  padding: 20px 0 60px;
}
@media all and (max-width: 991px) {
  .about-wrap {
    text-align: center;
  }
  .about-wrap .component-item:first-child {
    margin-bottom: 30px;
  }
}
@media all and (min-width: 992px) {
  .about .component-title-white {
    font-size: 48px;
  }
}
@media all and (min-width: 992px) {
  .about .component-subtitle-white {
    text-align: left;
    max-width: 480px;
  }
}
.about .cta-2 {
  margin-top: 30px;
}
@media all and (min-width: 768px) {
  .about-us-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media all and (max-width: 579px) {
  .about-us-list {
    max-width: 230px;
    margin: auto;
  }
}
.about-us-item {
  text-align: center;
  background-color: #ffffff;
  padding: 35px 10px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media all and (min-width: 767px) and (max-width: 991px) {
  .about-us-item {
    margin: 10px;
    flex: 1 0 40%;
  }
}
@media all and (min-width: 992px) {
  .about-us-item {
    flex: 0 0 35%;
    margin: 15px;
  }
  .about-us-item:nth-child(2n) {
    position: relative;
    top: 25px;
  }
}
@media all and (max-width: 991px) {
}
.about-us-title {
  color: #b20c2a;
  margin-top: 20px;
  font-weight: 700;
}
.about-us-icon img {
  margin: 0 auto;
}
.about-list {
  /* the parent */
}
.about-list .slick-slide {
  margin: 0 15px 15px;
}
.about-list .slick-list {
  margin: 0 -15px;
}
.about-us-list .slick-track {
  display: flex;
}
.about-us-list .slick-slide {
  display: flex;
  flex-flow: column wrap;
  height: auto;
  align-items: center;
  justify-content: center;
}
/* ----------------------------------------------------------------------
  PAYMENTS
   ---------------------------------------------------------------------- */
.payments-wrap {
  padding: 35px 0;
}
@media all and (max-width: 991px) {
  .payments-wrap {
    display: none;
  }
}
.payments-item img,
.payments-item object {
  width: 100%;
  max-width: 155px;
  filter: grayscale(100%);
}
.payments-item img:hover,
.payments-item object:hover {
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
  filter: none;
}
/* ----------------------------------------------------------------------
  RECENT ORDERS
   ---------------------------------------------------------------------- */
.orders-wrap {
  background-color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e3e7e8));
  background: -webkit-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -moz-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -ms-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -o-linear-gradient(140deg, #ffffff, #e3e7e8);
  padding-top: 130px;
  padding-bottom: 60px;
}
.orders-wrap .component-item:first-child {
  flex: 0 0 40%;
}
@media all and (min-width: 992px) {
  .orders-wrap .component-item:first-child .component-title {
    font-size: 48px;
    margin-top: 60px;
  }
}
@media all and (min-width: 1200px) {
  .orders-wrap .component-item:first-child .component-subtitle {
    max-width: 400px;
  }
}
.orders-wrap .component-item:last-child {
  flex: 0 0 60%;
}
@media screen and (max-width: 768px) {
  .orders-wrap {
    padding-top: 30px;
  }
}
.recent-orders-table {
  flex-direction: column;
  align-items: flex-end;
  color: #9499a5;
  line-height: 24px;
  font-size: 14px;
}
.recent-orders-header,
.recent-orders-item {
  justify-content: space-between;
  width: 100%;
}
.recent-orders-header-td:first-child,
.recent-orders-item-td:first-child {
  flex: 0 0 34%;
}
.recent-orders-header-td:nth-child(2),
.recent-orders-item-td:nth-child(2),
.recent-orders-header-td:nth-child(3),
.recent-orders-item-td:nth-child(3),
.recent-orders-header-td:nth-child(4),
.recent-orders-item-td:nth-child(4),
.recent-orders-header-td:last-child,
.recent-orders-item-td:last-child {
  flex: 1 0 7%;
  text-align: center;
}
@media all and (min-width: 768px) {
  .recent-orders-header,
  .recent-orders-item {
    flex-direction: row;
  }
}
@media all and (max-width: 768px) {
  .recent-orders-header {
    flex-direction: row;
    margin-top: 20px;
  }
}
@media all and (max-width: 767px) {
  .recent-orders-header {
    display: none;
  }
}
.recent-orders-header-td {
  margin-bottom: 10px;
}
.recent-orders-header-td:first-child {
  padding-left: 35px;
}
.recent-orders-header-td:last-child {
  padding-right: 25px;
  text-align: right;
}
.recent-orders-item {
  padding: 17px 0;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  margin: 7px 0;
}
.recent-orders-item-td:first-child {
  color: #636a76;
  padding-left: 35px;
}
.recent-orders-item-td:last-child {
  color: #288a1a;
  text-align: right;
  padding-right: 25px;
}
@media all and (max-width: 767px) {
  .recent-orders-item {
    margin: 5px;
    text-align: right;
  }
  .recent-orders-item-td:first-child,
  .recent-orders-item-td:nth-child(2),
  .recent-orders-item-td:nth-child(3),
  .recent-orders-item-td:nth-child(4),
  .recent-orders-item-td:last-child {
    text-align: right;
    padding: 5px 0;
  }
  .recent-orders-item-td:first-child:before,
  .recent-orders-item-td:nth-child(2):before,
  .recent-orders-item-td:nth-child(3):before,
  .recent-orders-item-td:nth-child(4):before,
  .recent-orders-item-td:last-child:before {
    display: inline-block;
    float: left;
    color: #9499a5;
    font-weight: 700;
  }
  .recent-orders-item-td:first-child:before {
    content: 'Topic';
  }
  .recent-orders-item-td:nth-child(2):before {
    content: 'Level';
  }
  .recent-orders-item-td:nth-child(3):before {
    content: 'Deadline';
  }
  .recent-orders-item-td:nth-child(4):before {
    content: 'Number of pages';
  }
  .recent-orders-item-td:last-child:before {
    content: 'Salary';
  }
}
@media all and (max-width: 579px) {
  .recent-orders-item {
    display: block;
  }
  .recent-orders-item-td:first-child,
  .recent-orders-item-td:nth-child(2),
  .recent-orders-item-td:nth-child(3),
  .recent-orders-item-td:nth-child(4),
  .recent-orders-item-td:last-child {
    padding: 5px 15px;
  }
}
.recent-orders-btn {
  width: 100%;
  text-align: center;
}
@media all and (min-width: 992px) {
  .recent-orders-btn {
    text-align: right;
  }
}
.recent-orders-slider {
  width: 100%;
}
@media all and (max-width: 767px) {
  .recent-orders-slider {
    margin-top: 20px;
  }
}
.more-btn {
  display: inline-block;
  text-align: center;
  margin-top: 25px;
  padding: 5px 30px;
  color: #b20c2a;
  font-weight: 700;
  border: 1px solid #b20c2a;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
/* ----------------------------------------------------------------------
  TEXT
   ---------------------------------------------------------------------- */
.text-wrap {
  padding: 55px 0;
  background-position: 50%;
  background-size: cover !important;
}
.text-wrap .component-item:first-child {
  flex: 1 0 52%;
}
@media all and (min-width: 992px) {
  .text-wrap .component-item:first-child .component-title-white {
    font-size: 36px;
  }
}
.text-wrap .component-item:first-child .component-subtitle-white {
  max-width: 590px;
}
.text-wrap .component-item:last-child {
  flex: 1 0 47%;
  text-align: center;
}
.text1-wrap {
  background: url(images/text1-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
}
.text2-wrap {
  background: url(images/text2-bg.png), linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
}
.text2-wrap .cta-2 {
  color: #000000;
}
.text3-wrap {
  background: url(images/text3-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
}
/* ----------------------------------------------------------------------
  GROWTH
   ---------------------------------------------------------------------- */
.growth-wrap {
  background-color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e3e7e8));
  background: -webkit-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -moz-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -ms-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -o-linear-gradient(135deg, #ffffff, #e3e7e8);
  padding-top: 90px;
  overflow: hidden;
}
.growth-wrap .component-title {
  text-align: center;
  margin-bottom: 20px;
}
.growth-wrap .component-subtitle {
  text-align: center;
  max-width: 770px;
  margin: auto;
}
@media all and (min-width: 992px) {
  .growth-wrap .component-subtitle {
    margin-bottom: 125px;
  }
}
@media all and (max-width: 768px) {
  .growth-wrap .slider__navigation {
    margin-bottom: 30px;
  }
}
.growth-item {
  position: relative;
  overflow: hidden;
  flex: 0 0 22%;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 4px 4px 0 0;
  padding: 25px 0 155px 20px;
  color: #636a76;
}
@media all and (max-width: 768px) {
  .growth-item {
    margin: 0 10px;
  }
}
@media all and (min-width: 768px) and (max-width: 768px) {
  .growth-item {
    flex: 1 0 44%;
    margin-bottom: 20px;
  }
}
.growth-item:first-child .growth-item-title {
  color: #278759;
}
.growth-item:nth-child(2) .growth-item-title {
  color: #3a507d;
}
.growth-item:nth-child(3) .growth-item-title {
  color: #eead49;
}
.growth-item:last-child .growth-item-title {
  color: #b20c2a;
}
.growth-item-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 25px;
}
.growth-item-list-info {
  line-height: 24px;
}
.growth-item-list-info:before {
  content: '\b7\a0';
  color: #b20c2a;
}
.growth-item-list-info span {
  font-weight: 700;
  font-size: 20px;
}
.growth-item-list:after {
  content: '';
  display: block;
  width: 128px;
  height: 1px;
  background-color: #c8d5d9;
  margin-top: 15px;
}
.growth-item:before {
  content: '';
  display: block;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #eef1f2;
  height: 210px;
  width: 210px;
  position: absolute;
  bottom: -50px;
  right: -45px;
}
.growth-item:after {
  content: '';
  display: block;
  width: 100px;
  height: 220px;
  position: absolute;
  bottom: 0;
  right: 20px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
}
.growth-item-first:after {
  background-image: url(images/growth/growth-1.png);
}
.growth-item-second:after {
  background-image: url(images/growth/growth-2.png);
}
.growth-item-second:after:after {
  animation-name: growth_2;
  animation-duration: 4s;
}
.growth-item-third:after {
  background-image: url(images/growth/growth-3.png);
}
.growth-item-third:after:after {
  animation-name: growth_3;
  animation-duration: 4s;
  animation-delay: 1s;
}
.growth-item-fourth:after {
  background-image: url(images/growth/growth-4.png);
}
.growth-item-fourth:after:after {
  animation-name: growth_4;
  animation-duration: 4s;
  animation-delay: 2s;
}
@media all and (max-width: 768px) {
  .growth-gradation-list {
    margin: 20px auto;
  }
}
@media all and (max-width: 580px) {
  .growth-gradation-list {
    max-width: 280px;
  }
}
@media all and (min-width: 768px) {
  .growth-gradation-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}
/* ----------------------------------------------------------------------
  HOW IT WORKS
   ---------------------------------------------------------------------- */
@media all and (min-width: 992px) {
  .hiw-wrap {
    padding-bottom: 105px;
  }
}
.hiw-wrap .component-title {
  text-align: center;
}
@media all and (min-width: 992px) {
  .hiw-steps {
    margin-top: 110px;
    margin-bottom: 70px;
  }
}
@media all and (max-width: 991px) {
  .hiw-steps {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .hiw-steps-item {
    flex: 1 0 50%;
    margin-bottom: 20px;
  }
}
.hiw-steps-item {
  text-align: center;
  position: relative;
}
@media all and (max-width: 767px) {
  .hiw-steps-item {
    margin-bottom: 0;
  }
}
.hiw-steps-item .hiw-steps-icon::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f105';
  font-weight: 900;
  font-size: 36px;
  z-index: 1;
  position: absolute;
  top: 18%;
  right: -12%;
}
@media all and (max-width: 1199px) {
  .hiw-steps-item .hiw-steps-icon::after {
    right: -10px;
  }
}
@media all and (max-width: 991px) {
  .hiw-steps-item .hiw-steps-icon::after {
    display: none;
  }
}
.hiw-steps-item:hover .hiw-steps-icon:before {
  transform: scale(1.2);
  transition-duration: 200ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.hiw-steps-item:nth-child(2) .hiw-steps-icon img {
  top: -1px;
}
.hiw-steps-item:nth-child(3) .hiw-steps-icon img {
  top: 11px;
}
.hiw-steps-item:nth-child(4) .hiw-steps-icon img {
  top: -5px;
}
.hiw-steps-item:nth-child(4) .hiw-steps-icon:after {
  display: none;
}
.hiw-steps-icon {
  min-height: 165px;
  position: relative;
}
.hiw-steps-icon:before {
  content: '';
  z-index: 1;
  position: absolute;
  bottom: 53px;
  left: 18%;
  display: block;
  width: 165px;
  height: 165px;
  background-color: #eef1f2;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
@media all and (max-width: 991px) {
  .hiw-steps-icon:before {
    display: none;
  }
}
.hiw-steps-icon img {
  position: relative;
  z-index: 2;
  margin: 0 auto;
}
.hiw-steps-title {
  font-size: 24px;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 15px;
  color: #b20c2a;
}
.hiw-steps-info {
  max-width: 260px;
  margin: auto;
  line-height: 24px;
}
/* ----------------------------------------------------------------------
  ABOUT JOB
   ---------------------------------------------------------------------- */
.about-job-wrap {
  background-color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e3e7e8));
  background: -webkit-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -moz-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -ms-linear-gradient(140deg, #ffffff, #e3e7e8);
  background: -o-linear-gradient(140deg, #ffffff, #e3e7e8);
  padding: 80px 0 60px;
}
.about-job-wrap .component-title {
  text-align: center;
  margin-bottom: 60px;
}
@media all and (min-width: 992px) {
  .about-job-wrap .component-title {
    font-size: 32px;
  }
}
.about-job-wrap .btn-wrap {
  margin-top: 50px;
}
.about-job-wrap .slider__navigation {
  text-align: center;
  margin-top: 20px;
}
.about-job-wrap .slick-arrow {
  cursor: pointer;
  padding: 0 35px;
  text-align: center;
  color: #8a1328;
  font-size: 24px;
  border: none;
  background-color: transparent;
}
.about-job-wrap .slick-arrow:hover {
  color: #c91d3c;
}
.about-job-wrap .slick-arrow:active {
  color: #8a1328;
}
.about-job-wrap .slick-arrow.slick-disabled {
  color: #e48e9d;
}
.about-job-list {
  /* the parent */
}
.about-job-list .slick-slide {
  margin: 0 15px 15px;
}
@media all and (max-width: 767px) {
  .about-job-list .slick-slide {
    text-align: center;
  }
}
.about-job-list .slick-list {
  margin: 0 -15px;
}
.about-job-item {
  padding: 35px 20px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
}
@media all and (min-width: 992px) {
  .about-job-item {
    min-height: 405px;
  }
}
@media all and (min-width: 768px) and (max-width: 1199px) {
  .about-job-item {
    min-height: 450px;
  }
}
.about-job-item-icon svg {
  max-width: 52px;
  max-height: 52px;
}
.about-job-item-title {
  font-size: 18px;
  color: #b20c2a;
  font-weight: 700;
  margin: 20px 0;
}
.about-job-item-text {
  line-height: 24px;
}
.about-job2-wrap {
  padding: 50px 0;
}
.about-job2-wrap .component-title {
  text-align: center;
  margin-bottom: 35px;
}
@media all and (min-width: 992px) {
  .about-job2-wrap .component-title {
    font-size: 32px;
  }
}
@media all and (min-width: 992px) {
  .about-job2-accordion {
    background-image: url(images/seo-bg.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    min-height: 650px;
  }
}
@media all and (min-width: 992px) {
  .about-job2-accordion .flex-first {
    flex: 0 0 57%;
  }
}
.content-accordion__header {
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  padding: 17px 20px;
  font-size: 23px;
  line-height: 20px;
  margin-bottom: 15px;
  position: relative;
  cursor: pointer;
  z-index: 10;
}
.content-accordion__header:before {
  content: '\f107';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  display: inline-block;
  font-size: 24px;
  color: #b20c2a;
  text-align: center;
  background-color: #ececec;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 29px;
  height: 29px;
  line-height: 30px;
  float: right;
  position: relative;
  top: -5px;
}
@media all and (max-width: 1199px) {
  .content-accordion__header:before {
    font-size: 14px;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}
.content-accordion__header:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.content-accordion__header.active:before {
  content: '\f106';
}
@media all and (max-width: 1199px) {
  .content-accordion__header {
    font-size: 14px;
  }
}
.content-accordion__header h1,
.content-accordion__header h2,
.content-accordion__header h3 {
  line-height: 20px;
  margin: 0;
  font-size: 23px;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}
.content-accordion__body {
  padding-top: 5px;
  padding-bottom: 15px;
  line-height: 24px;
  text-align: justify;
}
.content-accordion__body p {
  margin: 0;
}
/* ----------------------------------------------------------------------
  PRICE
   ---------------------------------------------------------------------- */
.price-wrap {
  background: url(images/oval-bg.png) repeat 50% 50%, linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
  background-size: cover !important;
}
.price-wrap .component-title-white {
  text-align: center;
  margin-bottom: 20px;
}
.price-wrap .component-subtitle-white {
  text-align: center;
  max-width: 1000px;
  margin: auto;
}
.price-wrap .note-line {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid #636976;
  max-width: 900px;
  margin: 50px auto 0;
  padding: 10px 20px;
  color: #ffffff;
}
.price-wrap .note-line-icon {
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 10px 8px;
  color: #9499a5;
  display: inline-grid;
  margin-right: 20px;
  font-size: 20px;
}
@media all and (max-width: 768px) {
  .price-wrap .note-line-icon {
    margin: 0 20px 10px 0;
    float: left;
  }
}
.price-grid {
  text-align: center;
  margin-top: 60px;
  line-height: 24px;
}
.price-grid-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
}
@media all and (max-width: 767px) {
  .price-grid-desc {
    font-size: 11px;
  }
}
.price-grid-table {
  margin-top: 10px;
}
.price-grid .component-item {
  flex: 0 0 49%;
}
@media all and (max-width: 768px) {
  .price-grid .component-item {
    min-width: 100%;
  }
}
@media all and (max-width: 768px) {
  .price-grid .component-item:last-child {
    margin-top: 30px;
  }
}
.price-grid-header,
.price-grid-content {
  flex-direction: row;
  width: 100%;
}
.price-grid-header-td,
.price-grid-item-td {
  flex: 1 0 33%;
}
.price-grid-header {
  padding: 10px 0;
  font-size: 14px;
}
@media all and (max-width: 768px) {
  .price-grid-header {
    display: none;
  }
}
.price-grid-header-td:first-child {
  text-align: left;
  padding-left: 15px;
}
.price-grid-item {
  margin: 10px 0;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.24);
  padding: 18px;
  background-color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.price-grid-item-td:first-child {
  text-align: left;
}
@media all and (max-width: 768px) {
  .price-grid-item {
    align-items: flex-end;
    text-align: right;
  }
  .price-grid-item-td {
    width: 100%;
  }
  .price-grid-item-td:before {
    float: left;
    color: #9499a5;
  }
  .price-grid-item-td:first-child {
    text-align: right;
  }
  .price-grid-item-td:first-child:before {
    content: 'Order Level';
  }
  .price-grid-item-td:nth-child(2):before {
    content: 'Urgent ( up to 24h)';
  }
  .price-grid-item-td:last-child:before {
    content: 'Non-urgent (from 24h)';
  }
}
/* ----------------------------------------------------------------------
  PAYMENT
   ---------------------------------------------------------------------- */
.how-pay-wrap {
  background-color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e3e7e8));
  background: -webkit-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -moz-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -ms-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -o-linear-gradient(135deg, #ffffff, #e3e7e8);
  padding: 50px 0;
}
.how-pay-icon img {
  width: 100%;
  max-width: 170px;
}
.how-pay-icon__revolut img {
  max-width: 143px;
}
.how-pay-icon__epayments object {
  min-width: 190px;
}
@media all and (min-width: 767px) and (max-width: 991px) {
  .how-pay-list {
    flex-wrap: wrap;
    display: flex;
  }
}
@media all and (min-width: 992px) {
  .how-pay-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: right;
  }
}
@media all and (max-width: 579px) {
  .how-pay-list {
    max-width: 230px;
    margin: 20px auto;
  }
}
.how-pay-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  padding: 0 10px;
}
.how-pay-item:not(:last-child) {
  text-align: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
@media all and (min-width: 767px) and (max-width: 991px) {
  .how-pay-item:not(:last-child) {
    margin: 10px;
    flex: 0 0 40%;
  }
}
@media all and (min-width: 1200px) {
  .how-pay-item:not(:last-child) {
    flex: 0 0 43%;
    margin: 15px 10px;
  }
}
@media all and (min-width: 992px) {
  .how-pay-item:not(:last-child) {
    flex: 0 0 43%;
    margin: 15px 7px;
  }
}
@media all and (max-width: 767px) {
  .how-pay-item:not(:last-child) {
    margin: 20px 0;
  }
}
@media all and (max-width: 767px) {
  .how-pay-item:last-child {
    height: auto;
  }
}
@media all and (min-width: 768px) {
  .how-pay-item {
    width: 263px;
  }
}
/* ----------------------------------------------------------------------
  STEPS
   ---------------------------------------------------------------------- */
.step-wrapper .note-line {
  line-height: 24px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid #d8d8d8;
  max-width: 390px;
  margin: 30px 0 0;
  padding: 10px 20px;
  color: #636a76;
}
.step-wrapper .note-line-icon {
  background-color: #bebebf;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  padding: 10px 8px;
  color: #ffffff;
  font-size: 20px;
  margin: 0 20px 30px 0;
  float: left;
}
.step-wrapper .component-subtitle-white {
  max-width: 450px;
}
.step-wrapper .component-item:first-child {
  flex: 0 0 37%;
  padding-left: 30px;
}
.step-wrapper .component-item:last-child {
  flex: 0 0 55%;
}
@media (min-width: 992px) {
  .side-line:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    content: '';
    background: #9499a5;
    z-index: 2;
  }
}
.steps-list-wrap {
  align-items: stretch;
  justify-content: space-between;
}
.steps-list-item {
  padding: 35px 15px;
  background-color: #ffffff;
  line-height: 24px;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  flex: 0 0 42%;
}
.steps-list-icon {
  position: relative;
  z-index: 2;
  text-align: center;
  height: 60px;
}
.steps-list-icon span {
  display: inline-block;
  border: 2px solid #d8d5d5;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  font-weight: 700;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
}
.steps-list-title {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: #b20c2a;
}
.steps-list-desc {
  margin-top: 30px;
  margin-bottom: 10px;
  min-height: 95px;
}
.steps-list-type li {
  list-style: none;
  display: inline-flex;
}
.steps-list-type li:before {
  content: '\f00c';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  font-size: 16px;
  color: #b20c2a;
  display: inline-table;
  margin-right: 10px;
}
.steps-list-info {
  color: #9499a5;
  font-size: 14px;
  text-align: center;
}
.finish-line {
  font-size: 32px;
  font-weight: 400;
  margin-top: 175px;
  padding-left: 30px;
  position: relative;
}
.finish-line:before {
  content: '\f00c';
  font-family: 'Font Awesome\ 5 Free';
  font-weight: 900;
  display: inline-block;
  text-align: center;
  line-height: 57px;
  width: 57px;
  height: 57px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 3;
  position: absolute;
  top: -10px;
  left: -60px;
  color: #b20c2a;
  background-color: #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step1-block {
  background: url(images/oval-bg.png) 50% 50%, linear-gradient(135deg, #87061e 0%, #be0829 100%);
  background-size: cover;
  padding: 80px 0 95px;
}
@media (min-width: 992px) {
  .step1-block .component-item:first-child:after {
    content: '\f0da';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    display: inline-block;
    width: 90px;
    height: 2px;
    text-align: right;
    background: #ffffff;
    color: #ffffff;
    margin-top: 20px;
    line-height: 2px;
    float: right;
  }
}
.step1-block .component-item:first-child .component-title-white {
  position: relative;
}
.step1-block .component-item:first-child .component-title-white:before {
  content: '1';
  display: inline-block;
  position: absolute;
  left: -40px;
  top: 42%;
  width: 20px;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  z-index: 3;
  line-height: 20px;
  text-align: center;
  color: #b20c2a;
  background-color: #ffffff;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step1-block .steps-list-title {
  margin-top: 20px;
}
.step1-block .side-line:before {
  height: 85%;
  bottom: -95px;
  background-color: #ffffff;
}
.step2-block {
  position: relative;
  z-index: 22;
  background-color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#e3e7e8));
  background: -webkit-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -moz-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -ms-linear-gradient(135deg, #ffffff, #e3e7e8);
  background: -o-linear-gradient(135deg, #ffffff, #e3e7e8);
  padding: 105px 0 105px;
}
.step2-block .steps-list-type {
  font-size: 14px;
}
.step2-block .steps-list-type:before {
  font-size: 14px;
}
.step2-block .component-title {
  position: relative;
}
.step2-block .component-title:before {
  content: '2';
  display: inline-block;
  position: absolute;
  left: -40px;
  top: 42%;
  width: 20px;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  z-index: 3;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #b20c2a;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step2-block .steps-list-item:first-child .steps-list-icon:after {
  content: '';
  display: inline-block;
  width: 50%;
  height: 2px;
  background-color: #f1f2f3;
  position: absolute;
  top: 12px;
  right: -15px;
}
.step2-block .steps-list-item:last-child .steps-list-icon:before {
  content: '';
  display: inline-block;
  width: 50%;
  height: 2px;
  background-color: #f1f2f3;
  position: absolute;
  top: 12px;
  left: -15px;
}
.step2-block .wait-block {
  position: absolute;
  bottom: -155px;
  right: 0;
  width: 90%;
  line-height: 24px;
  padding: 20px 30px;
  background-color: #ffffff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step2-block .side-line:before {
  bottom: -105px;
  height: 785px;
}
.step2-block .side-line:after {
  content: '';
  display: inline-block;
  width: 57px;
  height: 57px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 3;
  position: absolute;
  bottom: -135px;
  left: -30px;
  background: url(images/steps/wait.svg) no-repeat 50% 50%, #ffffff;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step3-block {
  background: url(images/steps/step-3.png) no-repeat 90% 50%, #ffffff;
  padding: 155px 0;
}
.step3-block .component-title,
.step3-block .component-subtitle {
  max-width: 450px;
}
.step3-block .component-title {
  position: relative;
}
.step3-block .component-title:before {
  content: '3';
  display: inline-block;
  position: absolute;
  left: -40px;
  top: 42%;
  width: 20px;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  z-index: 3;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #b20c2a;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 11px rgba(0, 0, 0, 0.1);
}
.step3-block .side-line:before {
  top: -240px;
  height: 710px;
}
/* ----------------------------------------------------------------------
  404
   ---------------------------------------------------------------------- */
.page-404 {
  background-image: linear-gradient(137deg, #e3e7e8 0%, #ffffff 50%);
  min-height: 95vh;
}
.page-404-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 95vh;
  position: static;
}
.page-404-content .component-title {
  color: #636a76;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-left: 10px;
}
.page-404-content .component-subtitle {
  color: #636a76;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 40px;
  padding-left: 10px;
}
.page-404-content:after {
  content: '';
  width: 100%;
  height: 95vh;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background: url('images/404-img.png') no-repeat center bottom;
}
.page-404-content .cta-1 {
  font-weight: bold;
}
.page-404__block {
  position: relative;
  padding-bottom: 140px;
  padding-right: 48px;
}
.page-404__block .component-text-404 {
  color: #dce0e2;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 300px;
  font-weight: 700;
  background-color: #eef1f2;
  padding: 118px 65px;
  border-radius: 100%;
  display: inline-block;
}
.page-404__block:first-child {
  z-index: 3;
  margin-bottom: 100px;
}
@media all and (max-width: 991px) {
  .page-404 {
    min-height: auto;
  }
  .page-404__block {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    text-align: center;
  }
  .page-404__block:first-child {
    order: 2;
  }
  .page-404__block .component-text-404 {
    background-color: transparent;
    padding: 0;
    color: #636a76;
    font-size: 180px;
  }
  .page-404-content {
    flex-wrap: wrap;
    min-height: auto;
    padding-top: 60px;
    padding-bottom: 50px;
  }
  .page-404-content .component-subtitle {
    margin-bottom: 0;
    text-align: center;
  }
  .page-404-content:after {
    display: none;
  }
  .page-404-content .component-title {
    text-align: center;
  }
}
/* ----------------------------------------------------------------------
  BREADCRUMBS
   ---------------------------------------------------------------------- */
.site-breadcrumbs {
  margin: 25px 0 15px;
  font-family: 'Roboto Condensed', sans-serif;
}
.site-breadcrumbs .home {
  text-decoration: underline;
}
/* ----------------------------------------------------------------------
   COPYWRITING PAGE
   ---------------------------------------------------------------------- */
/* ----------------------------------------------------------------------
  BUTTONS
   ---------------------------------------------------------------------- */
.copywriting .cta-1 {
  box-shadow: -3px 5px 15px 5px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  background: #9e0722;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.38px;
  font-family: Arial, Helvetica, sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}
.copywriting .cta-2 {
  box-shadow: -7px 7px 18px 3px rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  background: #ffffff;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: -0.38px;
  padding-left: 15px;
  padding-right: 15px;
}
.copywriting .sign-btn,
.copywriting .log-btn {
  font-weight: 400;
  font-size: 20px;
  min-width: 110px;
  transition: 400ms;
}
/* ----------------------------------------------------------------------
  HEADER
   ---------------------------------------------------------------------- */
.header-copywriting .header-item.header-item_logo {
  flex: 0 0 150px;
  margin-right: 0;
  transition: 400ms;
}
.header-copywriting .header-item.header-item_logo img {
  width: 150px;
  transition: 400ms;
}
.header-copywriting #menu-nav-menu {
  text-align: center;
}
.header-copywriting #menu-nav-menu li a {
  font-size: 21px;
  font-family: Arial;
  transition: 400ms;
}
.header-copywriting.scrollr .header-item.header-item_logo {
  flex: 0 0 110px;
}
.header-copywriting.scrollr .header-item.header-item_logo img {
  width: 110px;
}
.header-copywriting.scrollr #menu-nav-menu li a {
  font-size: 18px;
}
.header-copywriting.scrollr .sign-btn,
.header-copywriting.scrollr .log-btn {
  font-size: 16px;
  min-width: 80px;
}
@media (max-width: 992px) {
  .header-copywriting .header-item.header-item_logo {
    flex: 0 0 110px;
  }
  .header-copywriting .header-item.header-item_logo img {
    width: 110px;
  }
  .header-copywriting #menu-nav-menu li a {
    font-size: 18px;
  }
  .header-copywriting .sign-btn,
  .header-copywriting .log-btn {
    font-size: 16px;
    min-width: 80px;
  }
}
@media (max-width: 767px) {
  .header-copywriting .main-nav {
    padding-top: 80px;
  }
  .header-copywriting .main-nav .menu-item {
    margin-bottom: 5px;
  }
}
/* ----------------------------------------------------------------------
  ABOUT US
   ---------------------------------------------------------------------- */
.copywriting-about {
  padding: 70px 0 60px;
}
.copywriting-about .component-subtitle-white {
  font-size: 19px;
  max-width: 100%;
  line-height: 24px;
}
.copywriting-about .component-item:first-child {
  flex: 1 0 66%;
}
.copywriting-about .component-item:last-child {
  flex: 1 0 34%;
}
.copywriting-about .about-us-list {
  justify-content: center;
}
.copywriting-about .about-us-list .about-us-item {
  flex: 0 0 70%;
  box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 21px;
}
.copywriting-about .about-us-list .about-us-item:nth-child(2n) {
  top: 0;
}
.copywriting-about .about-us-list .about-us-title {
  font-size: 18px;
}
.copywriting-about .cta-2 {
  background-image: linear-gradient(180deg, #e7e7e7 0%, #ffffff 100%);
  margin-top: 40px;
  margin-bottom: 15px;
  font-size: 20px;
}
.copywriting-about .text-center {
  text-align: center;
  margin: 15px 0;
}
.copywriting-about .content-accordion__header {
  box-shadow: 0 7px 15px 1px rgba(0, 0, 0, 0.5);
  border: 1px solid #000000;
  background-color: #630516;
  color: #fff;
  font-size: 22px;
}
.copywriting-about .content-accordion__header:before {
  background-color: #b3a2a2;
}
.copywriting-about .content-accordion__body {
  background-color: rgba(255, 255, 255, 0.21);
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.38px;
  padding: 20px 10px;
  position: relative;
  top: -13px;
}
@media (max-width: 767px) {
  .copywriting-about .about-us-list .about-us-item {
    margin-bottom: 20px;
  }
}
@media (max-width: 579px) {
  .copywriting-about .about-us-list {
    max-width: 100%;
  }
}
/* ----------------------------------------------------------------------
  TEXT
   ---------------------------------------------------------------------- */
.copywriting-text .component-subtitle-white {
  font-size: 28px;
  letter-spacing: -0.57px;
  line-height: 35px;
}
/* ----------------------------------------------------------------------
  MAIN SCREEN
   ---------------------------------------------------------------------- */
.copywriting-main-screen .main-screen-content {
  padding-top: 100px;
  padding-bottom: 300px;
}
.copywriting-main-screen .main-screen-content .component-title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 10px;
}
.copywriting-main-screen .main-screen-content .component-subtitle {
  text-align: center;
  color: #abacad;
  font-size: 24px;
}
.copywriting-main-screen .main-screen-content .component-text {
  color: #000000;
  font-size: 24px;
  line-height: 35px;
  max-width: 460px;
  margin-top: 100px;
}
.copywriting-main-screen .animation .picture .picture__boy {
  top: 35.5%;
}
.copywriting-main-screen .animation .picture .picture__stairs {
  top: 43.2%;
}
@media (max-width: 1080px) {
  .copywriting-main-screen .main-screen-content .component-subtitle {
    width: 100%;
  }
  .copywriting-main-screen .main-screen-content .component-text {
    margin-top: 150px;
    max-width: 370px;
  }
  .copywriting-main-screen .animation .academia {
    top: 70px;
  }
  .copywriting-main-screen .animation .picture .picture__boy {
    top: 37.5%;
  }
  .copywriting-main-screen .animation .picture .picture__stairs {
    top: 44.5%;
  }
}
@media (max-width: 992px) {
  .copywriting-main-screen .main-screen-content .component-text {
    max-width: 370px;
    margin: 40px auto 0;
  }
}
@media (max-width: 500px) {
  .copywriting-main-screen .main-screen-content .component-title {
    font-size: 30px;
    line-height: 35px;
  }
}
/* ----------------------------------------------------------------------
  HOW IT WORK
   ---------------------------------------------------------------------- */
.copywriting-hiw {
  background-color: #f0f0f0;
}
.copywriting-hiw .component-subtitle {
  color: #000000;
  font-size: 23px;
  text-align: center;
  line-height: 35px;
  padding: 0 65px;
}
.copywriting-hiw .hiw-steps {
  align-items: inherit;
  margin-top: 90px;
}
.copywriting-hiw .hiw-steps-item {
  box-shadow: 6px 5px 22px rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  padding: 30px 10px 80px;
  box-sizing: border-box;
  width: 293px;
}
.copywriting-hiw .hiw-steps-icon {
  min-height: 140px;
}
.copywriting-hiw .hiw-steps-icon:before,
.copywriting-hiw .hiw-steps-icon:after {
  display: none;
}
.copywriting-hiw .hiw-steps-title {
  font-size: 24px;
  min-height: 60px;
}
.copywriting-hiw .hiw-steps-info {
  text-align: left;
  color: #717070;
  font-size: 16px;
  padding: 0 25px;
}
@media (max-width: 992px) {
  .copywriting-hiw .component-subtitle {
    font-size: 20px;
    line-height: 30px;
  }
  .copywriting-hiw .hiw-steps {
    display: block;
    margin-top: 50px;
  }
  .copywriting-hiw .hiw-steps-item {
    margin: 25px auto;
  }
}
@media (max-width: 500px) {
  .copywriting-hiw .component-subtitle {
    padding: 0;
  }
}
.copywriting .cta-1 {
  font-size: 20px;
}
/* ----------------------------------------------------------------------
  TESTIMONIALS
   ---------------------------------------------------------------------- */
.copywriting-testimonials .testimonials-item {
  margin-bottom: 80px;
  box-shadow: 2px 3px 4px 2px rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  padding: 0;
}
.copywriting-testimonials .testimonials-inner {
  padding: 20px 10px 20px 25px;
  display: flex;
  align-items: center;
}
.copywriting-testimonials .testimonials-icon {
  width: 20%;
}
.copywriting-testimonials .testimonials-info {
  width: 80%;
}
.copywriting-testimonials .testimonials-text:before {
  display: none;
}
.copywriting-testimonials .component-title-white {
  text-align: center;
  font-size: 48px;
  margin-bottom: 100px;
}
.copywriting-testimonials .component-subtitle-white {
  text-align: center;
  font-size: 23px;
  line-height: 35px;
}
.copywriting-testimonials .component-item:first-child {
  flex: 0 0 39%;
}
.copywriting-testimonials .component-item:last-child {
  flex: 0 0 50%;
  height: auto;
}
.copywriting-testimonials .component-question {
  color: #eaeaea;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 150px;
  text-align: center;
  margin-bottom: 45px;
}
.copywriting-testimonials .cta-1 {
  font-size: 20px;
}
@media (max-width: 992px) {
  .copywriting-testimonials .component-title-white {
    margin-bottom: 40px;
    font-size: 48px;
  }
  .copywriting-testimonials .component-subtitle-white {
    font-size: 20px;
    line-height: 30px;
  }
  .copywriting-testimonials .component-question {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .copywriting-testimonials .component-item:last-child {
    padding-left: 0;
  }
  .copywriting-testimonials .testimonials-item {
    margin-bottom: 20px;
  }
}
@media (max-width: 340px) {
  .copywriting-testimonials .component-question {
    font-size: 30px;
  }
  .copywriting-testimonials .cta-1 {
    font-size: 18px;
  }
  .copywriting-testimonials .testimonials-inner {
    display: block;
  }
}
/* ----------------------------------------------------------------------
  GROWN GRADATION
   ---------------------------------------------------------------------- */
.copywriting-growth .component-title {
  font-family: 'Roboto Condensed', sans-serif;
}
.copywriting-growth .component-subtitle {
  color: #000000;
  font-size: 23px;
  line-height: 35px;
  max-width: 88%;
  margin-top: 60px;
  margin-bottom: 90px;
}
.copywriting-growth .growth-item {
  flex: 0 0 20%;
  padding: 25px 20px 220px 20px;
  box-shadow: 1px 0 5px 1px rgba(0, 0, 0, 0.5);
}
.copywriting-growth .growth-item-title {
  color: #9e0722 !important;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  line-height: 60px;
}
.copywriting-growth .growth-item-title:after {
  content: '';
  width: 100%;
  height: 1px;
  background: #9e0722;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: block;
}
.copywriting-growth .growth-item-list-info {
  color: #717070;
  font-size: 16px;
  text-align: center;
}
.copywriting-growth .growth-item-list-info:before,
.copywriting-growth .growth-item-list-info:after {
  display: none;
}
.copywriting-growth .growth-item-list:before,
.copywriting-growth .growth-item-list:after {
  display: none;
}
@media (max-width: 992px) {
  .copywriting-growth .growth-gradation-list {
    display: block;
  }
  .copywriting-growth .growth-item {
    max-width: 200px;
    margin: 0 auto 25px;
  }
  .copywriting-growth .component-subtitle {
    font-size: 20px;
    line-height: 30px;
    max-width: 100%;
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
/* ----------------------------------------------------------------------
  FAQ
   ---------------------------------------------------------------------- */
.faq {
  background-image: url(images/oval-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.faq-title {
  color: #9e0722;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid #979797;
  background: rgba(216, 216, 216, 0.65);
  padding: 15px 35px;
  font-family: Helvetica;
}
.faq-questions {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
}
.faq-questions-question {
  font-size: 21px;
}
.faq-questions-answer {
  padding: 20px 0;
  font-size: 21px;
}
.faq .cta-1 {
  min-width: 150px;
}
@media (max-width: 500px) {
  .faq-title {
    font-size: 28px;
    padding: 15px 0;
  }
}
/* ----------------------------------------------------------------------
  FOOTER
   ---------------------------------------------------------------------- */
.footer-copywriting {
  font-size: 14px;
  font-weight: bold;
  padding: 50px 0 20px;
}
.footer-copywriting .footer-logo {
  margin-bottom: 15px;
}
.footer-copywriting .footer-logo img {
  width: 150px;
}
.footer-copywriting .footer-phone svg {
  max-width: 150px;
  max-height: 35px;
}
.footer-copywriting .footer-copy {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
}
.footer-copywriting #copyriting-footer {
  margin-top: 25px;
}
.footer-copywriting #copyriting-footer li {
  display: block;
  padding: 5px 23px;
}
.footer-copywriting #copyriting-footer li a {
  color: #817c7c;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.35px;
  display: inline-block;
}
/* ----------------------------------------------------------------------
   REGISTRATION PAGE
   ---------------------------------------------------------------------- */
.header__registration {
  background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
  max-height: 115px;
  padding: 14px 0;
}
@media (max-width: 767px) {
  .header__registration {
    max-height: 95px;
  }
}
.header__registration.scrollr .main-nav a {
  color: #ffffff;
}
@media (max-width: 767px) {
  .header__registration.scrollr .main-nav a {
    color: #b20c2a;
  }
}
.header__registration .logo-link img {
  max-width: none;
}
@media (max-width: 767px) {
  .header__registration .logo-link img {
    padding-top: 8px;
  }
}
@media (max-width: 767px) {
  .header__registration .phone-link {
    background: none;
  }
}
@media (max-width: 767px) {
  .header__registration .header-item_phone {
    background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
    padding-bottom: 0;
  }
}
.header__registration .slicknav_menu > a {
  padding-top: 22px;
}
@media (max-width: 767px) {
  .header__registration .header-wrap {
    padding: 0!important;
    margin-top: -26px;
  }
}
.header__registration .slicknav_nav {
  padding: 0 0 17px;
}
.header__registration .header-item_menu {
  padding-top: 55px;
}
@media (max-width: 1199px) {
  .header__registration .header-item_menu {
    padding-top: 0;
  }
}
.header__registration .header-item_logo {
  border: none;
  background: none !important;
}
@media (max-width: 767px) {
  .header__registration .header-item_logo {
    display: none;
  }
}
.header__registration .slicknav_icon span {
  background: #ffffff;
}
.header__registration .phone-link {
  width: 169px;
  height: 19px;
  font-family: "Roboto Condensed";
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}
@media (max-width: 767px) {
  .header__registration .phone-link {
    font-size: 14px;
  }
}
.header__registration .phone-link span {
  font-weight: 700;
}
.header__registration .sign-btn {
  background: none;
  color: #000000;
}
.header__registration .sign-btn:hover {
  background-color: #000000;
  color: #ffffff;
}
.header__registration .log-btn {
  border: 1px solid transparent;
}
.header__registration .log-btn:hover {
  border: 1px solid #000000;
  color: #000000;
}
@media (max-width: 1199px) {
  .header__registration .main-nav #menu-nav-menu li a {
    color: #ffffff;
  }
}
.header-registration__mobile-logo {
  height: 66px;
  text-align: center;
  padding-top: 27px;
  position: relative;
  z-index: 200;
  background-image: linear-gradient(132deg, #87071e 0%, #df294a 100%);
}
@media (min-width: 768px) {
  .header-registration__mobile-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  .header-registration__mobile-logo {
    display: block;
    position: relative;
    background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
  }
}
.registration-blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Roboto Condensed";
  font-weight: 400;
  margin: 237px auto 130px;
  max-width: 1170px;
  width: 90%;
}
@media (max-width: 768px) {
  .registration-blocks {
    flex-direction: column;
    margin-top: 175px;
    margin-bottom: 120px;
    justify-content: space-between;
    align-items: center;
  }
}
@media (max-width: 425px) {
  .registration-blocks {
    margin-bottom: 66px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .registration-blocks {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .registration-blocks {
    justify-content: center;
  }
}
.applicants-block {
  width: 35%;
  position: relative;
}
.applicants-block-wrapper {
  position: relative;
  z-index: 1;
  box-shadow: 0.5px 0.5px 5px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  padding: 30px 0px 110px 0px;
}
@media (max-width: 768px) {
  .applicants-block {
    width: 100%;
    text-align: center;
  }
}
@media (min-width: 992px) {
  .applicants-block-wrapper {
    background-color: #fff;
    padding: 30px 0px 110px 0px;
    height: 70%;
  }
  .applicants-block:first-of-type::after {
    content: url("images/registration-page/reg-bg1.png");
    position: absolute;
    left: -203px;
    bottom: 10px;
    z-index: 0;
  }
  .applicants-block:last-of-type:before {
    content: url("images/registration-page/reg-bg2.png");
    position: absolute;
    right: -232px;
    bottom: 10px;
    z-index: 0;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .applicants-block {
    margin: 0px 20px;
  }
  .applicants-block:first-of-type::after {
    bottom: 26px;
    left: -160px;
  }
  .applicants-block:last-of-type:before {
    right: -160px;
    bottom: 29px;
  }
}
@media (min-width: 1024px) {
  .applicants-block {
    margin: 0px 1%;
  }
}
.applicants-block__copywriters {
  text-align: left;
}
@media (max-width: 768px) {
  .applicants-block__copywriters {
    margin-bottom: 70px;
    text-align: center;
  }
}
@media (max-width: 374px) {
  .applicants-block__copywriters .applicants-block__title {
    padding-bottom: 19px;
    line-height: 60px;
  }
}
.applicants-block__copywriter-text {
  padding-bottom: 10px !important;
}
.applicants-block__btn {
  text-transform: uppercase;
  font-weight: 700;
  width: 256px;
  cursor: pointer;
  margin: 55px auto 0px;
  text-align: center;
  display: block;
  border: none;
}
.applicants-block__btn-2 {
  margin-top: 46px;
}
@media (min-width: 992px) and (max-width: 1200px) {
  .applicants-block__btn-2 {
    margin-top: -8px;
  }
}
@media (min-width: 1200px) {
  .applicants-block__btn-2 {
    margin-top: 46px;
  }
}
@media (max-width: 991px) {
  .applicants-block__btn {
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .applicants-block__btn {
    margin: 32px auto 0;
  }
}
.applicants-block__title {
  color: #000000;
  font-size: 38px;
  line-height: 60px;
  padding-bottom: 22px;
  text-align: center;
  font-family: 'Playfair Display', serif;
}
@media (max-width: 425px) {
  .applicants-block__title {
    font-size: 36px;
    text-align: center;
  }
}
@media (max-width: 374px) {
  .applicants-block__title {
    line-height: 38px;
    padding-bottom: 38px;
  }
}
.applicants-block__text {
  font-size: 16px;
  line-height: 24px;
  max-width: 430px;
  padding: 0px 30px 15px;
  height: 45%;
  margin: 0 auto;
}
.applicants-block__subjects-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .applicants-block__subjects-container {
    justify-content: space-between;
    width: 76%;
    text-align: left;
    margin: 0 auto;
  }
}
@media (max-width: 650px) {
  .applicants-block__subjects-container {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .applicants-block__subjects-container {
    flex-direction: column;
  }
}
.applicants-block__subjects {
  padding-right: 4px;
}
@media (max-width: 425px) {
  .applicants-block__subjects-second {
    margin-top: -28px;
  }
}
.applicants-block__list {
  list-style-type: none;
}
@media (max-width: 425px) {
  .applicants-block__list {
    text-align: center;
  }
}
.applicants-block__item {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #636a76;
  padding-bottom: 5px;
}
.applicants-block__item::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-image: url("images/registration-page/Oval.png");
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 5px 6px;
}
@media (max-width: 425px) {
  .applicants-block__item::before {
    display: none;
  }
}
.checkbox-terms {
  background-image: url("images/registration-page/checkbox.png");
  background-position: center center;
  background-repeat: no-repeat;
}
.registration-form {
  background-image: linear-gradient(135deg, #e4e8e9 0%, #ffffff 100%);
  padding-top: 120px;
  padding-bottom: 100px;
}
@media (max-width: 500px) {
  .registration-form {
    padding-top: 66px;
  }
}
.registration-form #registration__terms {
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;
}
.registration-form__checkbox {
  margin-right: 5px;
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #979797;
  background-color: #ffffff;
}
.registration-form__terms-privacy {
  position: relative;
  text-align: center;
  margin-top: 55px;
}
.registration-form__terms-privacy a {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .registration-form__terms-privacy {
    margin-top: 25px;
  }
}
.registration-form input:-webkit-autofill,
.registration-form input:-webkit-autofill:hover,
.registration-form input:-webkit-autofill:focus textarea:-webkit-autofill,
.registration-form textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0 40px white inset;
  background-color: #ffffff !important;
  border: 1px solid #979797 !important;
}
.registration-form form {
  margin: 0 auto;
  max-width: 1170px;
  width: 90%;
}
.registration-form__flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 975px) {
  .registration-form__flex {
    flex-direction: column;
  }
}
.registration-form__inputs {
  display: inline-block;
}
.registration-form__files {
  display: inline-block;
}
@media (max-width: 500px) {
  .registration-form__files {
    margin-top: 15px;
  }
}
.registration-form__info {
  vertical-align: middle;
  cursor: pointer;
  padding-left: 1px;
}
.registration-form__text {
  color: #636a76;
  font-size: 16px;
  line-height: 24px;
  max-width: 457px;
  padding-bottom: 50px;
}
@media (width: 768px) {
  .registration-form__text {
    padding: 15px 0 25px 0;
    margin: 0 auto;
    text-align: center;
  }
}
.registration-form__text-mobile {
  margin: 0 auto;
  text-align: center;
  color: #636a76;
  font-size: 16px;
  line-height: 24px;
  max-width: 457px;
  padding-bottom: 30px;
  display: none;
}
@media (max-width: 767px) {
  .registration-form__text-mobile {
    display: block;
    text-align: center;
  }
}
@media (max-width: 1100px) {
  .registration-form__text {
    max-width: 372px;
  }
}
@media (max-width: 975px) {
  .registration-form__text {
    max-width: 67%;
  }
}
@media (max-width: 767px) {
  .registration-form__text {
    display: none;
  }
}
.registration-form__no-files {
  display: block;
  opacity: 0.61;
  color: #929292;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-top: 10px;
}
@media (max-width: 767px) {
  .registration-form__no-files {
    position: absolute;
    top: 56px;
    left: 21%;
  }
}
.registration-form #myFiles {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 17px;
}
@media (max-width: 768px) {
  .registration-form #myFiles {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.registration-form .remove {
  cursor: pointer;
  position: absolute;
  background-image: url("images/registration-page/remove.png");
  width: 17px;
  height: 17px;
  opacity: 0.55;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  left: -19px;
}
.registration-form .registration-form__max-size {
  display: none;
  opacity: 0.61;
  color: #9A0822;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-top: 7px;
}
@media (max-width: 767px) {
  .registration-form .registration-form__max-size {
    position: absolute;
    top: 86px;
    left: -3%;
    width: 206px;
  }
}
.registration-form .file-container {
  position: relative;
  color: #9A0822;
  font-size: 16px;
  padding-bottom: 5px;
}
.registration-form__drop-zone {
  flex-direction: column;
  width: 449px;
  height: 191px;
  background-color: #ffffff;
  position: relative;
  border: 3px dashed #d4d4d4;
  display: flex;
  justify-content: center;
}
@media (max-width: 1100px) {
  .registration-form__drop-zone {
    width: 372px;
    height: 170px;
  }
}
@media (max-width: 975px) {
  .registration-form__drop-zone {
    width: 60%;
    height: 20vw;
  }
}
@media (width: 768px) {
  .registration-form__drop-zone {
    margin: 0 auto;
  }
}
@media (max-width: 767px) {
  .registration-form__drop-zone {
    width: 182px;
    height: 60px;
    border: none;
    margin: 0 auto;
  }
}
.registration-form__drop-zone-text {
  color: #cccccc;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
@media (max-width: 767px) {
  .registration-form__drop-zone-text {
    display: none;
  }
}
.registration-form__drop-zone-btn {
  font-size: 20px;
  font-weight: 700;
  color: #757575;
  line-height: 60px;
  width: 182px;
  border: 1px solid rgba(39, 39, 39, 0.33);
  background-color: #e4e4e4;
  text-align: center;
  margin: 10px auto 0;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .registration-form__drop-zone-btn {
    margin-top: 0;
  }
}
.registration-form__file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.registration-form__info-text {
  z-index: 1000;
  transition: 0.2s;
  display: none;
  position: absolute;
  top: 45px;
  left: 95px;
  width: 300px;
  height: 122px;
  font-size: 16px;
  font-weight: 400;
  background-color: #303132;
  color: #ffffff;
  border-radius: 4px;
  text-align: justify;
  padding: 12px 15px;
  box-sizing: border-box;
  text-align: center;
}
@media (max-width: 767px) {
  .registration-form__info-text {
    width: 276px;
    top: -133px;
    left: 0px;
    padding: 12px 0;
  }
}
.registration-form__info-text::before {
  content: '';
  position: absolute;
  top: -22px;
  left: 22px;
  border: 8px solid transparent;
  border-bottom: 16px solid #303132;
}
@media (max-width: 767px) {
  .registration-form__info-text::before {
    content: '';
    position: absolute;
    top: auto;
    bottom: -18px;
    left: 116px;
    border: 8px solid transparent;
    border-top: 16px solid #303132;
  }
}
.registration-form__input-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #636a76;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  width: 515px;
  padding-bottom: 15px;
}
.registration-form__input-row:last-child label {
  position: relative;
}
@media (max-width: 767px) {
  .registration-form__input-row label {
    padding-bottom: 10px;
  }
}
@media (max-width: 1100px) {
  .registration-form__input-row {
    width: 467px;
  }
}
@media (max-width: 975px) {
  .registration-form__input-row {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .registration-form__input-row {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
  }
}
.registration-form__input-row input {
  border-radius: 4px;
  border: 1px solid #979797;
  height: 50px;
  width: 345px;
  padding: 20px 10px;
  box-sizing: border-box;
}
@media (max-width: 1100px) {
  .registration-form__input-row input {
    width: 300px;
  }
}
@media (max-width: 975px) {
  .registration-form__input-row input {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .registration-form__input-row input {
    width: 96%;
  }
}
.registration-form__input-row #registration__letter {
  resize: vertical;
  width: 345px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  height: 111px;
  box-sizing: border-box;
  border-radius: 4px;
}
@media (max-width: 1100px) {
  .registration-form__input-row #registration__letter {
    width: 300px;
  }
}
@media (max-width: 975px) {
  .registration-form__input-row #registration__letter {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .registration-form__input-row #registration__letter {
    width: 96%;
  }
}
.registration-form__button {
  font-weight: 700;
  text-transform: uppercase;
  min-width: 256px;
  cursor: pointer;
  margin: 20px auto 0;
  display: block;
  border: none;
}
.registration-form .error-input,
.registration-form .error-input-email {
  background: rgba(239, 99, 98, 0.2);
}
.registration-form .input-warning,
.registration-form .input-warning-email {
  color: rgba(239, 99, 98, 0.9);
  display: block;
  padding-bottom: 16px;
  margin-top: -6px;
}
.registration-form .input-warning-terms,
.registration-form .input-warning-email-terms {
  margin-top: 10px;
  text-align: center;
  padding-bottom: 0;
}
.popup-success {
  font-family: "Roboto Condensed";
  position: relative;
  z-index: 1000;
  margin: 135px auto 0;
  padding: 20px 20px 90px;
  width: 510px;
  max-width: 75%;
  background: #ffff;
}
@media (max-width: 767px) {
  .popup-success {
    padding: 20px 10px 60px;
  }
}
@media (max-width: 425px) {
  .popup-success {
    padding: 4px 4px 38px;
  }
}
.popup-success__container {
  display: none;
  width: 100%;
  min-height: 100%;
  background-color: rgba(109, 105, 114, 0.41);
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 100;
}
.popup-success__btn {
  display: block;
  text-align: right;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border-style: none;
  cursor: pointer;
  background-image: url("images/registration-page/popup-close.png");
  background-position: center;
  background-repeat: no-repeat;
}
.popup-success__title {
  text-align: center;
  color: #636a76;
  font-size: 60px;
  font-weight: 700;
  padding-bottom: 21px;
}
@media (max-width: 767px) {
  .popup-success__title {
    font-size: 45px;
  }
}
@media (max-width: 425px) {
  .popup-success__title {
    font-size: 40px;
    margin-top: -8px;
  }
}
.popup-success__text {
  color: #636a76;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}
@media (max-width: 767px) {
  .popup-success__text {
    font-size: 23px;
  }
}
@media (max-width: 425px) {
  .popup-success__text {
    font-size: 20px;
  }
}
.popup-success__btn-cnt {
  padding-bottom: 13px;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 425px) {
  .popup-success__btn-cnt {
    padding-bottom: 0px;
  }
}
