.footer{
  &-wrap{
    background-color: @wc;
    font-size: 14px;
    padding: 80px 0 30px;
    @media all and  (max-width: @max-md){
      text-align: center;
      padding: 20px 0;
    }
    .flex-first,
    .flex-last{
      flex: 1 0 33%;
      @media all and  (max-width: @max-sm){
        margin-bottom: 10px;
      }
    }
    .flex-last{
      @media all and  (min-width: @min-md){
        text-align: right;
      }
    }

  }
  &-logo{
    margin-bottom: 30px;
    .logo-link {
      display: inline-block;
      svg {
        max-width: 100px;
        max-height: 40px;
      }
    }
    @media all and  (max-width: @max-md){
      text-align: center;
      margin-bottom: 10px;
    }

  }

  &-phone{
    margin-bottom: 5px;
    svg {
      max-width: 147px;
      max-height: 20px;
    }
    @media all and  (max-width: @max-xs){
      text-align: center;
      margin-bottom: 20px;
    }
  }

  &-wrap .fb{
    margin-top: 20px;
  }

  &-copy{
    margin-top: 30px;
    font-size: 10px;
    @media all and  (max-width: @max-md) {
      margin-top: 20px;
    }
  }

  &-links{
    margin-top: 45px;
    @media all and  (max-width: @max-md){
      margin-top: 15px;
    }
    .country-spain,.country-france{
      width: 25px;
      height: 25px;
      margin-top: 2px;
    }
    ul{
      display: flex;
      justify-content: flex-end;
      margin: 0;
      @media all and  (max-width: @max-md){
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    li{
      list-style: none;
      padding: 0 5px;
      @media all and  (max-width: @max-md){
        padding: 10px 5px;
        flex: 1 0 20%;
      }
    }
  }
}

#menu-footer{
  display: flex;
  flex-wrap: wrap;
  @media all and  (max-width: @max-md) {
    flex-direction: column;
  }
    li{
    flex: 0 0 33%;
    padding: 10px 0;
    @media all and  (max-width: @max-md){
      font-size: 16px;
      flex: 1 0 100%;
    }
  }
  a{
    color: @gc2;
    display: inline-block;
  }
}

.footer-info{
  font-size: 10px;
  padding: 40px 20px 0;
  text-align: center;
  color: #9499a5;
}