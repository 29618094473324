.animation{
  .academia{
    position: absolute;
    right: 19.8%;
    top: 20px;
    z-index: 1;
    .letter{
      color: #d1dee3;
      font-family: @playfair;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
      position: absolute;
      padding: 100px 50px;
      &__1{
        transform: rotate(-19deg);
        right: 420px;
        top: 110px;
      }
      &__2{
        right: 346px;
        top: 107px;
      }
      &__3{
        transform: rotate(23deg);
        top: 40px;
        right: 307px;
      }
      &__4{
        transform: rotate(-12deg);
        top: 63px;
        right: 190px;
      }
      &__5{
        top: 112px;
        right: 117px;
      }
      &__6{
        transform: rotate(28deg);
        top: 62px;
        right: 20px;
      }
      &__7{
        top: 107px;
        right: -62px;
      }
      &__8{
        top: 150px;
        right: -110px;
      }
    }
  }
  .picture{
    img{
      transition: all 0.5s ease-in-out;
      position: absolute;

    }
    img.picture__boy{
      width: 55px;
      top: 34.2%;
      right: 23.5%;
      animation: main-picture_boy 5s linear alternate infinite;

    }
    img.picture__stairs{
      width: 125px;
      top: 42.2%;
      right: 23%;
      transform-origin: bottom right;
      animation: main-picture_stairs 5s linear alternate infinite;

    }
    img.picture__girl{
      width: 65px;
      top: 62%;
      right: 27.8%;
      transform-origin: bottom right;
      animation: main-picture_girl 5s linear alternate infinite;
      transform: rotate(-3deg);
    }
  }
  .research{
    text-align: right;
    position: absolute;
    bottom: 45px;
    width: 100%;
    right: 15%;
  }
}
@keyframes main-picture_boy {
  0%, 15%{
    transform: rotate(4deg) translateX(10px);
  }
  25%, 100%{
    transform: rotate(-1deg) translateX(-2px);
  }
}
@keyframes main-picture_stairs {
  0%, 15%{
    transform: rotate(1deg);
  }
  25%, 100%{
    transform: rotate(-1deg);
  }
}
@keyframes main-picture_girl {
  0%{
    transform: rotate(-1deg);
  }
  10%{
    transform: rotate(-1deg);
  }
  15%{
    transform: rotate(-3deg);
  }
  25%{
    transform: rotate(-3deg);
  }
  100%{
    transform: rotate(-1deg);
  }
}
@media screen and  (max-width: 1600px){
  .animation .picture img.picture__girl {
    right: 28.8%;
  }
}
@media screen and  (max-width: 1440px){
  .animation {
    .picture{
      img.picture__boy{
        right: 15.7%;
      }
      img.picture__stairs{
        right: 15%;
      }
      img.picture__girl{
        right: 21.5%;
      }
    }
    .academia {
      right: 10%;
    }
    .research {
      right: 4%;
    }
  }
}
@media screen and  (max-width: 1280px){
  .animation {
    .picture{
      img.picture__girl{
        right: 22%;
      }
    }
  }
}
@media screen and  (max-width: @max-lg){
  .animation .picture img.picture__girl {
    right: 31.7%;
  }
}
@media screen and  (max-width: 1080px){
  .animation {
    .picture{
      img.picture__boy{
        right: 20.7%;
      }
      img.picture__stairs{
        right: 20%;
      }
      img.picture__girl{
        right: 29%;
      }
    }
    .academia {
      right: 15%;
    }
  }
}
@media screen and  (max-width: @max-md){
  .animation{
    display: none;
  }
}

/*-------------------
  HOVER ICON ANIMATE
--------------------*/

#Path-2, #Onboarding, #Path-21{
  transition: all 4s ease-in-out;
}
.dollar, .transform{
  transition: all 0.5s ease-in-out;
}
@keyframes transform {

  0%{
    transform: rotate(0deg);
  }
  25%{
    transform: rotate(-14deg);
  }
  50%{
    transform: rotate(0deg);
  }
  75%{
    transform: rotate(14deg);
  }
  100%{
    transform: rotate(0deg);
  }
}
