.testimonials{
  &-wrap{
    background-image: url(images/oval-bg.png), linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
    background-size: cover !important;
    padding: 50px 0;
    .component-wrap{
      position: relative;
      align-items: center;
      justify-content: space-between;
    }
    .slider__navigation {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      width: auto;

      @media all and  (max-width: @max-sm) {
        text-align: center
      }

      @media only screen and  (max-width: @max-sm){
        position: relative;
        top: auto;
        left: auto;
        transform: none;

        &_flex{
          display: flex;
          max-width: 240px;
          margin: 20px auto 0;

          .slider-arrow_next,
          .slider-arrow_prev{
            transform: rotate(-90deg);
          }
        }
      }
    }
    .sitejabber-icon{
      display: inline-block;
      margin-top: 45px;

      @media only screen and  (max-width: @max-sm){
        margin-top: 20px;
      }
    }

    .component-item:first-child{
      flex: 0 0 35%;
      @media all and (max-width: @max-md){
        margin-bottom: 20px;
        text-align: center;
      }

      @media all and (max-width: @max-md){
        .component-title-white{
          text-align: center;
        }
      }

      @media all and (max-width: @max-sm){
        flex: 1 0 auto;

        .component-title-white{
          text-align: center;
        }
      }
    }
    .component-item:last-child{
      position: relative;
      padding-left: 70px;
      overflow: hidden;
      @media (min-width: @min-lg){
        height: 504px;
      }
      @media (min-width: @min-md){
        height: 550px;
      }
      @media (min-width: @min-sm){

      }
      @media all and  (min-width: @min-md){
        .slider__navigation{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      @media only screen and (max-width: @max-sm){
        padding-left: 0;
      }
    }

    svg{
      max-width: 72px;
      max-height: 72px;
    }

    .slick-arrow {
      display: block !important;
      background-color: transparent;
      text-align: center;
      margin: auto;
      color: @wc;
      font-size: 24px;
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      border: none;
      padding: 10px;
      &:hover {
        color: #9499a5;
      }
      @media all and  (max-width: @max-sm){
        display: inline-block !important;
        padding: 0 15px;
        &:hover {
          color: #fff;
        }
      }

      &.slick-disabled{
        color: #9499a5;
      }
    }
    .slick-dots{
      text-align: center;
      list-style: none;
      @media all and  (min-width: @min-sm){
        margin-bottom: 45px;
      }
      li{
        display: inline-block;
        button{
          font-size: 0;
          text-align: center;
          display: inline-block;
          vertical-align: middle;
          background-color: transparent;
          margin: 6px;
          cursor: pointer;
          transition: .25s;
        }
        &.slick-active {
          button{
            background-color: transparent;
            transform: scale(1.5);
          }
        }
      }
    }
  }
  &-list{
    overflow: hidden;
    .slick-slide {
      padding: 5px 0;
      border: none;
      //float: none;
      @media all and  (max-width: @max-sm){
        margin: 0 10px;
      }
    }
    /* the parent */
    .slick-list {
      padding: -5px 0;
      position: relative;
    }
  }
  &-inner{
    padding: 35px 15px 35px 20px;
    background: @wc;
    .border-radius(4px);
    .box-shadow(0, 2px, 8px, rgba(0, 0, 0, 0.5));
  }
  &-icon{
    min-height: 80px;
    @media all and  (min-width: @min-sm){
      display: inline-block;
      width: 12%;
      padding-right: 25px;
    }
    @media all and  (max-width: @max-sm){
      text-align: center;
    }
    img{
      display: inline-block;
    }
  }
  &-info{
    @media all and  (min-width: @min-sm){
      display: inline-block;
      width: 78%;
    }
  }
  &-text{
    line-height: 24px;
    margin-bottom: 15px;
    &:before{
      content: '\f10d';
      font-family: 'Font Awesome 5 Free';
      font-weight:900;
      font-size: 24px;
      color: @rc2;
      padding-right: 5px;
    }
  }
  &-title{
    color: @bc;
  }
  &-about{
    color: @gc3;
  }
}