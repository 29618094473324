.hiw-wrap{
  @media all and  (min-width: @min-md){
    padding-bottom: 105px;
  }
  .component-title{
    text-align: center;
  }
}
.hiw-steps{
  @media all and  (min-width: @min-md){
    margin-top: 110px;
    margin-bottom: 70px;
  }
  @media all and  (max-width: @max-md){
    flex-wrap: wrap;
    flex-direction: row;
    &-item{
      flex: 1 0 50%;
      margin-bottom: 20px;
    }
  }
  &-item{
    text-align: center;
    position: relative;
    @media all and  (max-width: @max-sm){
      margin-bottom: 0;
    }

    .hiw-steps-icon::after{
      //display: none;
      font-family: 'Font Awesome 5 Free';
      content: '\f105';
      font-weight: 900;
      font-size: 36px;
      z-index: 1;
      position: absolute;
      top: 18%;
      right: -12%;
      @media all and  (max-width: @max-lg){
        right: -10px;
      }
      @media all and  (max-width: @max-md) {
        display: none;
      }
    }
    &:hover{
      & .hiw-steps-icon:before{
        transform: scale(1.2);
        .anime;
      }
    }
    &:nth-child(2){
      .hiw-steps-icon {
        img {
          top: -1px;
        }
      }
    }
    &:nth-child(3){
      .hiw-steps-icon {
        img {
          top: 11px;
        }
      }
    }
    &:nth-child(4){
      .hiw-steps-icon{
        img{
          top: -5px;
        }
        &:after{
          display: none;
        }
      }
    }
  }
  &-icon{
    min-height: 165px;
    position: relative;
    &:before{
      content: '';
      z-index: 1;
      position: absolute;
      bottom: 53px;
      left: 18%;
      display: block;
      width: 165px;
      height:165px;
      background-color: #eef1f2;
      .border-radius(100%);
      @media all and  (max-width: @max-md) {
        display: none;
      }
    }
    img{
      position: relative;
      z-index: 2;
      margin: 0 auto;
    }
  }
  &-title{
    font-size: 24px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 15px;
    color: @rc2;
  }
  &-info{
    max-width: 260px;
    margin: auto;
    line-height: 24px;
  }
}