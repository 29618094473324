
.header__registration {
  background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
  max-height: 115px;
  padding: 14px 0;
  @media (max-width: 767px) {
    max-height: 95px;
  }

  &.scrollr .main-nav a {
    color: #ffffff;
    @media (max-width: 767px){
      color: #b20c2a;
    }
  }

  .logo-link {
    img {
      max-width: none;
      @media (max-width: 767px) {
        padding-top: 8px;
      }
    }
  }

  .phone-link {
    @media(max-width: 767px) {
      background: none;
    }
  }

  .header-item_phone {
    @media(max-width: 767px) {
      background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
      padding-bottom: 0;
    }
  }

  .slicknav_menu > a {
    padding-top: 22px;
  }

  .header-wrap {
    @media (max-width: 767px) {
      padding: 0!important;
      margin-top: -26px;
    }
  }

  .slicknav_nav {
    padding: 0 0 17px;
  }

  .header-item_menu {
    padding-top: 55px;
    @media (max-width: 1199px) {
      padding-top: 0;
    }
  }

  .header-item_logo {
    border: none;
    background: none !important;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .slicknav_icon span {
    background: #ffffff;
  }

  .phone-link {
    width: 169px;
    height: 19px;
    font-family: "Roboto Condensed";
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    @media (max-width: 767px) {
      font-size: 14px;
    }

    span {
      font-weight: 700;
    }
  }

  .sign-btn {
    background: none;
    color: #000000;

    &:hover {
      background-color: #000000;
      color: #ffffff;
    }
  }

  .log-btn {
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #000000;
      color: #000000;
    }
  }

  .main-nav {
    #menu-nav-menu li a {
      @media (max-width: 1199px) {
        color: #ffffff;
      }
    }
  }
}

.header-registration__mobile-logo {
  height: 66px;
  text-align: center;
  padding-top: 27px;
  position: relative;
  z-index: 200;
  background-image: linear-gradient(132deg, #87071e 0%, #df294a 100%);
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 767px) {
    display: block;
    position: relative;
    background-image: linear-gradient(135deg, #87071e 0%, #bf092a 100%);
  }
}
.registration-blocks{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-family: "Roboto Condensed";
  font-weight: 400;
  margin: 237px auto 130px;
  max-width: 1170px;
  width: 90%;
  @media(max-width: 768px){
    flex-direction: column;
    margin-top: 175px;
    margin-bottom: 120px;
    justify-content: space-between;
    align-items: center;
  }
  @media(max-width: 425px){
    margin-bottom: 66px;
  }
  @media(min-width: 992px) and (max-width: 1200px){
    justify-content: center;
  }
  @media(min-width: 1200px){
    justify-content: center;
  }
}
.applicants-block {
  width: 35%;
  position: relative;
  &-wrapper{
    position: relative;
    z-index: 1;
    box-shadow: 0.5px 0.5px 5px 0px rgba(0,0,0,0.4);
    background-color: #fff;
    padding: 30px 0px 110px 0px;
  }
  @media(max-width: 768px){
    width: 100%;
    text-align: center;
  }
  @media(min-width: 992px){
    &-wrapper{
      background-color: #fff;
      padding: 30px 0px 110px 0px;
      height: 70%;
    }
    &:first-of-type{
      &::after{
        content: url("images/registration-page/reg-bg1.png");
        position: absolute;
        left: -203px;
        bottom: 10px;
        z-index: 0;
      }
    }
    &:last-of-type{
      &:before{
        content: url("images/registration-page/reg-bg2.png");
        position: absolute;
        right: -232px;
        bottom: 10px;
        z-index: 0;
      }
    }
  }
  @media(min-width: 992px) and (max-width: 1200px){
    margin: 0px 20px;
    &:first-of-type{
      &::after{
        bottom: 26px;
        left: -160px;
      }
    }
    &:last-of-type{
      &:before{
        right: -160px;
        bottom: 29px;
      }
    }
  }
  @media(min-width: 1024px){
      margin: 0px 1%;
  }
  &__copywriters{
    text-align: left;
    @media(max-width: 768px){
      margin-bottom: 70px;
      text-align: center;
    }
    .applicants-block__title{
      @media(max-width: 374px){
        padding-bottom: 19px;
        line-height: 60px;
      }
    }
  }
  &__copywriter-text{
    padding-bottom: 10px !important;
  }
  &__btn{
    text-transform: uppercase;
    font-weight: 700;
    width: 256px;
    cursor: pointer;
    margin: 55px auto 0px;
    text-align: center;
    display: block;
    border: none;
    &-2{
      margin-top: 46px;
      @media(min-width: 992px) and (max-width: 1200px){
        margin-top: -8px;
      }
      @media(min-width: 1200px) {
        margin-top: 46px;
      }
    }
  @media(max-width: 991px){
    margin-left: 0;
  }
  @media(max-width: 768px){
    margin: 32px auto 0;
  }

  }

  &__title {
    color: #000000;
    font-size: 38px;
    line-height: 60px;
    padding-bottom: 22px;
    text-align: center;
    font-family: 'Playfair Display',serif;
    @media (max-width: 425px) {
      font-size: 36px;
      text-align: center;
    }
    @media(max-width: 374px){
      line-height: 38px;
      padding-bottom: 38px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    max-width: 430px;
    padding: 0px 30px 15px;
    height: 45%;
    margin: 0 auto;
  }

  &__subjects-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 768px) {
      justify-content: space-between;
      width: 76%;
      text-align: left;
      margin: 0 auto;
    }
    @media(max-width: 650px){
      width: 100%;
    }
    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  &__subjects {
    padding-right: 4px;

    &-second {
      @media (max-width: 425px) {
        margin-top: -28px;
      }
    }
  }

  &__list {
    list-style-type: none;
    @media (max-width: 425px) {
      text-align: center;
    }
  }

  &__item {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #636a76;
    padding-bottom: 5px;

    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      background-image: url("images/registration-page/Oval.png");
      background-position: center;
      background-repeat: no-repeat;
      padding: 0 5px 6px;
      @media (max-width: 425px) {
        display: none;
      }
    }
  }
}
.checkbox-terms{
  background-image: url("images/registration-page/checkbox.png");
  background-position: center center;
  background-repeat: no-repeat;
}
.registration-form {
  background-image: linear-gradient(135deg, #e4e8e9 0%, #ffffff 100%);
padding-top: 120px;
padding-bottom: 100px;

@media (max-width: 500px){
  padding-top: 66px;
}

#registration__terms{
  position: absolute;
  width: 20px;
  height: 20px;
  opacity: 0;

}
&__checkbox{
  margin-right: 5px;
  display: inline-block;
  vertical-align: bottom;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border: 1px solid #979797;
  background-color: #ffffff;
}

&__terms-privacy{
  position: relative;
  text-align: center;
  margin-top: 55px;
  a {
    text-decoration: underline;
  }
  @media(max-width: 768px){
    margin-top: 25px;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus{
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0 40px white inset;
  background-color: #ffffff !important;
  border: 1px solid #979797 !important;
}
  form {
    margin: 0 auto;
    max-width: 1170px;
    width: 90%;
  }
  &__flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 975px){
      flex-direction: column;
    }
  }

  &__inputs {
    display: inline-block;

  }

  &__files {
    display: inline-block;
    @media (max-width: 500px){
      margin-top: 15px;
    }
  }
  &__info{
    vertical-align: middle;
    cursor: pointer;
    padding-left: 1px;
  }

  &__text {
    color: #636a76;
    font-size: 16px;
    line-height: 24px;
    max-width: 457px;
    padding-bottom: 50px;
    @media (width: 768px){
      padding: 15px 0 25px 0;
    margin: 0 auto;
    text-align: center;
    }
    &-mobile{
      margin: 0 auto;
      text-align: center;
      color: #636a76;
      font-size: 16px;
      line-height: 24px;
      max-width: 457px;
      padding-bottom: 30px;
      display: none;
      @media (max-width: 767px){
        display: block;
        text-align: center;
      }
    }
    @media (max-width: 1100px){
      max-width: 372px;
    }
    @media (max-width: 975px){
      max-width: 67%;
    }
    @media (max-width: 767px){
      display: none;
    }
  }
&__no-files{
  display: block;
opacity: 0.61;
color: #929292;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
padding-top: 10px;
@media (max-width: 767px){
    position: absolute;
    top: 56px;
    left: 21%;
}
}
#myFiles{
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 17px;
  @media (max-width: 768px){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.remove{
  cursor: pointer;
  position: absolute;
  background-image: url("images/registration-page/remove.png");
  width: 17px;
  height: 17px;
  opacity: 0.55;
  background-repeat: no-repeat;
  background-position: center center;
  top: 0;
  left: -19px;
}
.registration-form__max-size {
  display: none;
  opacity: 0.61;
  color: #9A0822;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  padding-top: 7px;
  @media (max-width: 767px){
    position: absolute;
    top: 86px;
    left: -3%;
    width: 206px;
  }
}
.file-container{
  position:relative;
  color: #9A0822;
  font-size: 16px;
  padding-bottom: 5px;
}
  &__drop-zone {
    flex-direction: column;
    width: 449px;
    height: 191px;
    background-color: #ffffff;
    position: relative;
    border: 3px dashed #d4d4d4;
    display: flex;
    justify-content: center;
    @media (max-width:1100px ){
      width: 372px;
      height: 170px;
    }
    @media (max-width: 975px){
      width: 60%;
      height: 20vw;
    }
    @media (width: 768px){
    margin: 0 auto;
    }
    @media (max-width: 767px){
          width: 182px;
          height: 60px;
          border: none;
          margin: 0 auto;
    }
    &-placeholder {

    }

    &-text {
      color: #cccccc;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      @media (max-width: 767px){
        display: none;
      }
    }

    &-btn {
      font-size: 20px;
      font-weight: 700;
      color: #757575;
      line-height: 60px;
      width: 182px;
      border: 1px solid rgba(39, 39, 39, 0.33);
      background-color: #e4e4e4;
      text-align: center;
      margin: 10px auto 0;
      border-radius: 10px;
      @media (max-width: 767px){
        margin-top: 0;
      }
    }
  }
  &__file-input{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
  }
&__info-text{
  z-index: 1000;
  transition: 0.2s;
  display: none;
  position: absolute;
  top: 45px;
  left: 95px;
  width: 300px;
  height: 122px;
  font-size: 16px;
  font-weight: 400;
  background-color: #303132;
  color: #ffffff;
  border-radius: 4px;
  text-align: justify;
  padding: 12px 15px;
  box-sizing: border-box;
    text-align: center;
  @media (max-width: 767px){
    width: 276px;
    top: -133px;
    left: 0px;
    padding: 12px 0;
  }
  &::before{
    content: '';
    position: absolute;
    top: -22px;
    left: 22px;
    border: 8px solid transparent;
    border-bottom: 16px solid #303132;
    @media (max-width: 767px){
      content: '';
      position: absolute;
      top: auto;
      bottom: -18px;
      left: 116px;
      border: 8px solid transparent;
      border-top: 16px solid #303132;
    }

  }
}
  &__input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #636a76;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    width: 515px;
    padding-bottom: 15px;
    &:last-child label{
      position: relative;
    }
    label{
      @media (max-width: 767px){
        padding-bottom: 10px;
      }
    }
    @media (max-width: 1100px){
      width: 467px;
    }
    @media (max-width: 975px){
      width: 100%;
    }
    @media (max-width: 767px){
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 16px;
    }
    input {
      border-radius: 4px;
      border: 1px solid #979797;
      height: 50px;
      width: 345px;
      padding: 20px 10px;
      box-sizing: border-box;
      @media (max-width: 1100px){
       width: 300px;
      }
      @media (max-width: 975px){
       width: 60%;
      }
      @media (max-width: 767px){
      width: 96%;
      }
    }
    #registration__letter{
      resize: vertical;
      width: 345px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      height: 111px;
      box-sizing: border-box;
      border-radius: 4px;
      @media (max-width:1100px ){
       width: 300px;
      }
      @media (max-width: 975px){
       width: 60%;
      }
      @media (max-width: 767px){
       width: 96%;
      }
    }
  }
&__button{
  font-weight: 700;
  text-transform: uppercase;
  min-width: 256px;
  cursor: pointer;
  margin: 20px auto 0;
display: block;
border: none;
}
.error-input, .error-input-email{
  background: rgba(239, 99, 98, 0.2);
}
.input-warning, .input-warning-email{
color: rgba(239, 99, 98, 0.9);
display: block;
padding-bottom: 16px;
margin-top: -6px;
&-terms{
  margin-top: 10px;
  text-align: center;
  padding-bottom: 0;
}
}
}


.popup-success {
  font-family: "Roboto Condensed";
  position: relative;
  z-index: 1000;
  margin: 135px auto 0;
  padding: 20px 20px 90px;
  width: 510px;
  max-width: 75%;
  background: #ffff;
  @media (max-width: 767px){
      padding: 20px 10px 60px;
  }
  @media (max-width: 425px){
        padding: 4px 4px 38px;
  }
  &__container {
    display: none;
    width: 100%;
    min-height: 100%;
    background-color: rgba(109,105,114,0.41);
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 100;
  }

  &__btn {
    display: block;
    text-align: right;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-style: none;
    cursor: pointer;
    background-image:url("images/registration-page/popup-close.png");
    background-position: center;
    background-repeat: no-repeat;
  }

  &__title {
    text-align: center;
    color: #636a76;
    font-size: 60px;
    font-weight: 700;
    padding-bottom: 21px;
    @media (max-width: 767px){
      font-size: 45px;
    }
    @media (max-width: 425px){
      font-size: 40px;
      font-size: 40px;
    margin-top: -8px;
    }
  }

  &__text {
    color: #636a76;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
    @media (max-width: 767px){
      font-size: 23px;
    }
    @media (max-width: 425px){
      font-size: 20px;
    }
  }
  &__btn-cnt{
        padding-bottom: 13px;
    display: flex;
    justify-content: flex-end;
    @media(max-width: 425px){
      padding-bottom: 0px;
    }
  }
}
