.footer-copywriting{
  font-size: 14px;
  font-weight: bold;
  padding: 50px 0 20px;
    .footer{
      &-logo {
        margin-bottom: 15px;
        img{
          width: 150px;
        }
      }
      &-phone{
        svg {
          max-width: 150px;
          max-height: 35px;
        }
      }
      &-copy {
        margin-top: 15px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  #copyriting-footer{
    margin-top: 25px;
    li{
      display: block;
      padding: 5px 23px;
      a{
        color: #817c7c;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.35px;
        display: inline-block;
      }
    }
  }
}
