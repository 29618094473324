#slider-1,
#slider-2,
#slider-3,
#slider-4,
#slider-5,
#slider-6{
  .slick-slide img{
    display: inline-block;
  }

}

.slider__navigation{

  @media screen and  (max-width: @min-sm){
    text-align: center;
    margin-top: 20px;
    width: 100%;
    .slick-arrow {
      padding: 0 35px;
      text-align: center;
      font-size: 24px;
      border: none;
      background: none;
      &:hover {
        color: #c91d3c;
      }
      &:active{
        color: #8a1328;
      }
    }
  }

  &_sm {
    display: none;

    .slick-initialized & {
      display: block;
    }
  }
}

.slider__navigation_light{
  .slick-arrow{
    color: #fff;

    &.slick-disabled{
      color: #e68d9c;
    }
  }

  .pagingInfo{
    color: #e68d9c;

    > span{
      color: #fff;
    }
  }
}

.slider__navigation_dark{
  .slick-arrow{
    color: #c91d3c;

    &.slick-disabled{
      color: #e48e9d;
    }
  }

  .pagingInfo{
    color: #9499a5;

    > span{
      color: #000;
    }
  }
}

.slider-5__navigation{
  @media all and  (max-width: (@max-sm + 1px )){
    margin-top: 20px;
  }
}
.pagingInfo{
  font-size: 18px;
}