.about-job{
  &-wrap{
    .linear-gradient(140deg, @wc, #e3e7e8);
    padding: 80px 0 60px;
    .component-title{
      text-align: center;
      @media all and  (min-width: @min-md){
        font-size: 32px;
      }
      margin-bottom: 60px;
    }
    .btn-wrap{
      margin-top: 50px;
    }

    .slider__navigation{
      text-align: center;
      margin-top: 20px;
    }
    .slick-arrow {
      cursor: pointer;
      padding: 0 35px;
      text-align: center;
      color: #8a1328;
      font-size: 24px;
      border: none;
      background-color: transparent;
      &:hover {
        color: #c91d3c;
      }
      &:active{
        color: #8a1328;
      }
      &.slick-disabled{
        color: #e48e9d;
      }
    }
  }
  &-list{
    .slick-slide {
      margin: 0 15px 15px;
      @media all and  (max-width: @max-sm){
        text-align: center;
      }
    }
    /* the parent */
    .slick-list {
      margin: 0 -15px;
    }
  }
  &-item{
    @media all and  (min-width: @min-md){
      min-height: 405px;
    }
    padding: 35px 20px;
    background-color: @wc;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.24));
    @media all and  (min-width: @min-sm) and (max-width: @max-lg){
      min-height: 450px;
    }
    &-icon{
      svg{
        max-width: 52px;
        max-height: 52px;
      }
    }
    &-title{
      font-size: 18px;
      color: @rc2;
      font-weight: 700;
      margin: 20px 0;
    }
    &-text{
      line-height: 24px;
    }
  }
}