.copywriting {

  &-text {
    .component-subtitle-white {
      font-size: 28px;
      letter-spacing: -0.57px;
      line-height: 35px;
    }

  }
}