.text{
  &-wrap{
    padding: 55px 0;
    background-position: 50%;
    background-size: cover !important;
    .component-item:first-child{
      flex: 1 0 52%;
      .component-title-white{
        @media all and  (min-width: @min-md){
          font-size: 36px;
        }
      }
      .component-subtitle-white {
        max-width: 590px;
      }
    }
    .component-item:last-child{
      flex: 1 0 47%;
      text-align: center;
    }
  }
}
.text1-wrap{
  background: url(images/text1-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
}
.text2-wrap{
  background: url(images/text2-bg.png), linear-gradient(-46deg, #636976 0%, #2a2a2a 100%);
  .cta-2{
    color: @bc;
  }
}
.text3-wrap{
  background: url(images/text3-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
}