.page-404 {
  background-image: linear-gradient(137deg, #e3e7e8 0%, #ffffff 50%);
  min-height: 95vh;
  &-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 95vh;
    position: static;
    .component-title{
      color: @gc3;
      font-family: @roboto;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 10px;
      padding-left: 10px;
    }
    .component-subtitle{
      color: @gc3;
      font-family: @roboto;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 40px;
      padding-left: 10px;
    }
    &:after{
      content: '';
      width: 100%;
      height: 95vh;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      background: url('images/404-img.png') no-repeat center bottom;
    }
    .cta-1{
      font-weight: bold;
    }
  }
  &__block{
    position: relative;
    padding-bottom: 140px;
    padding-right: 48px;
    .component-text-404{
      color: #dce0e2;
      font-family: @roboto;
      font-size: 300px;
      font-weight: 700;
      background-color: #eef1f2;
      padding: 118px 65px;
      border-radius: 100%;
      display: inline-block;
    }
    &:first-child{
      z-index: 3;
      margin-bottom: 100px;
    }
  }
  @media all and  (max-width: (@max-md) ) {
    min-height: auto;
    &__block{
      margin: 0 !important;
      padding: 0 !important;
      width: 100%;
      text-align: center;
      &:first-child{
        order: 2;
      }
      .component-text-404{
        background-color: transparent;
        padding: 0;
        color: @gc3;
        font-size: 180px;
      }
    }
    &-content {
      flex-wrap: wrap;
      min-height: auto;
      padding-top: 60px;
      padding-bottom: 50px;
      .component-subtitle{
        margin-bottom: 0;
        text-align: center;
      }
      &:after{
        display: none;
      }
      .component-title{
       text-align: center;
      }

    }
  }
}