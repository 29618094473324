.step-wrapper {
  .note-line{
    line-height: 24px;
    .border-radius(4px);
    border: 2px solid #d8d8d8;
    max-width: 390px;
    margin: 30px 0 0;
    padding: 10px 20px;
    color: @gc3;
    &-icon{
      background-color: #bebebf;
      .border-radius(100%);
      padding: 10px 8px;
      color: @wc;
      font-size: 20px;
      margin: 0 20px 30px 0;
      float: left;

    }
  }
  .component-subtitle-white{
    max-width: 450px;
  }
  .component-item:first-child{
    flex: 0 0 37%;
    padding-left: 30px;
  }
  .component-item:last-child{
    flex: 0 0 55%;
  }
}
@media (min-width: @min-md) {
  .side-line:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
    content: '';
    background: @gc2;
    z-index: 2;
  }
}
.steps-list {
  &-wrap {
    align-items: stretch;
    justify-content: space-between;
  }
  &-item{
    padding: 35px 15px;
    background-color: @wc;
    line-height: 24px;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
    flex: 0 0 42%;
  }
  &-icon{
    position: relative;
    z-index: 2;
    text-align: center;
    height: 60px;
    & span{
      display: inline-block;
      border: 2px solid #d8d5d5;
      .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
      font-weight: 700;
      .border-radius(100%);
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
  &-title{
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: @rc2;
  }
  &-desc{
    margin-top: 30px;
    margin-bottom: 10px;
    min-height: 95px;
  }
  &-type{
    & li{
      list-style: none;
      display: inline-flex;
      &:before{
        content: '\f00c';
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        font-size: 16px;
        color: @rc2;
        display: inline-table;
        margin-right: 10px;
      }
    }
  }
  &-info{
    color: @gc2;
    font-size: 14px;
    text-align: center;
  }
}
.finish-line{
  font-size: 32px;
  font-weight: 400;
  margin-top: 175px;
  padding-left: 30px;
  position: relative;
  &:before{
    content: '\f00c';
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    display: inline-block;
    text-align: center;
    line-height: 57px;
    width: 57px;
    height: 57px;
    .border-radius(100%);
    z-index: 3;
    position: absolute;
    top: -10px;
    left: -60px;
    color: @rc2;
    background-color: @wc;
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
  }
}

.step1-block{
    background: url(images/oval-bg.png) 50% 50%, linear-gradient(135deg, #87061e 0%, #be0829 100%);
    background-size: cover;
    padding: 80px 0 95px;
  .component-item:first-child{
    @media (min-width: @min-md){
      &:after{
        content: '\f0da';
        font-family: 'Font Awesome\ 5 Free';
        font-weight: 900;
        display: inline-block;
        width: 90px;
        height: 2px;
        text-align: right;
        background: @wc;
        color: @wc;
        margin-top: 20px;
        line-height: 2px;
        float: right;
      }
    }
    .component-title-white{
      position: relative;
      &:before{
        content:'1';
        display: inline-block;
        position: absolute;
        left: -40px;
        top: 42%;
        width: 20px;
        font-size: 16px;
        font-family: @roboto;
        z-index: 3;
        line-height: 20px;
        text-align: center;
        color: @rc2;
        background-color: @wc;
        .border-radius(100%);
        .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));

      }
    }
  }
  .steps-list-title{
    margin-top: 20px;
  }

  .side-line:before {
    height: 85%;
    bottom: -95px;
    background-color: @wc;
  }
}
.step2-block{
  position: relative;
  z-index: 22;
  .linear-gradient;
  padding: 105px 0 105px;
  .steps-list-type{
    font-size: 14px;
    &:before{
      font-size: 14px;
    }
  }
  .component-title{
    position: relative;
    &:before{
      content:'2';
      display: inline-block;
      position: absolute;
      left: -40px;
      top: 42%;
      width: 20px;
      font-size: 16px;
      font-family: @roboto;
      z-index: 3;
      line-height: 20px;
      text-align: center;
      color: @wc;
      background-color: @rc2;
      .border-radius(100%);
      .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));

    }
  }
  .steps-list-item:first-child {
    .steps-list-icon{
      &:after{
        content: '';
        display: inline-block;
        width: 50%;
        height: 2px;
        background-color: #f1f2f3;
        position: absolute;
        top: 12px;
        right: -15px;

      }
    }
  }
  .steps-list-item:last-child {
    .steps-list-icon{
      &:before{
        content: '';
        display: inline-block;
        width: 50%;
        height: 2px;
        background-color: #f1f2f3;
        position: absolute;
        top: 12px;
        left: -15px;
      }
    }
  }
  .wait-block{
    position: absolute;
    bottom: -155px;
    right: 0;
    width: 90%;
    line-height: 24px;
    padding: 20px 30px;
    background-color: @wc;
    .border-radius(4px);
    .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
  }
  .side-line{
    &:before {
      bottom: -105px;
      height: 785px;
    }
    &:after{
      content: '';
      display: inline-block;
      width: 57px;
      height: 57px;
      .border-radius(100%);
      z-index: 3;
      position: absolute;
      bottom: -135px;
      left: -30px;
      background: url(images/steps/wait.svg) no-repeat 50% 50%, @wc;
      .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));
    }
  }
}
.step3-block{
  background: url(images/steps/step-3.png) no-repeat 90% 50%, @wc;
  padding: 155px 0;
  .component-title,
  .component-subtitle{
    max-width: 450px;
  }
  .component-title{
    position: relative;
    &:before{
      content:'3';
      display: inline-block;
      position: absolute;
      left: -40px;
      top: 42%;
      width: 20px;
      font-size: 16px;
      font-family: @roboto;
      z-index: 3;
      line-height: 20px;
      text-align: center;
      color: @wc;
      background-color: @rc2;
      .border-radius(100%);
      .box-shadow(0, 6px, 11px, rgba(0, 0, 0, 0.1));

    }
  }
  .side-line:before {
    top: -240px;
    height: 710px;
  }
}