.about{
  &-wrap {
    background-image: url(images/oval-bg.png), linear-gradient(135deg, #87061e 0%, #be0829 100%);
    background-position: 50% 0;
    background-size: cover !important;
    padding: 20px 0 60px;
    @media all and  (max-width: @max-md){
      text-align: center;
      .component-item:first-child{
        margin-bottom: 30px;
      }
    }
  }
  .component-title-white{
    @media all and  (min-width: @min-md){
      font-size: 48px;
    }
  }
  .component-subtitle-white {
    @media all and  (min-width: @min-md){
      text-align: left;
      max-width: 480px;
    }
  }
  .cta-2{
    margin-top: 30px;
  }
  &-us{
    &-list{
      @media all and  (min-width: @min-sm){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      @media all and  (max-width: @max-xs){
        max-width: 230px;
        margin: auto;
      }
    }
    &-item{

      text-align: center;
      background-color: @wc;
      padding: 35px 10px;
      .border-radius(4px);
      @media all and  (min-width: (@min-sm - 1px)) and (max-width: @max-md){
        margin: 10px;
        flex: 1 0 40%;
      }
      @media all and  (min-width: @min-md){
        flex: 0 0 35%;
        margin: 15px;
        &:nth-child(2n){
          position: relative;
          top: 25px;
        }
      }
      @media all and  (max-width: @max-md){
        //width: 230px !important;
      }
    }

    &-title{
      color: @rc2;
      margin-top: 20px;
      font-weight: 700;
    }

    &-icon{
      img{
        margin: 0 auto;
      }
    }
  }
  &-list{
    .slick-slide {
      margin: 0 15px 15px;
    }
    /* the parent */
    .slick-list {
      margin: 0 -15px;
    }


  }
}

.about-us-list{
  .slick-track{
    display: flex;
  }

  .slick-slide{
    display: flex;
    flex-flow: column wrap;
    height: auto;
    align-items: center; //optional
    justify-content: center; //optional
  }
}